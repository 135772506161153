<template>
    <div class="promotions">
        <LoadingScreen v-if="isLoading" />
        <div v-else class="promotions--games">
            <template v-if="!promotionSelected">
                <h2 class="promotions--title">{{ getTranslatedText('promo games') }}</h2>
                <div class="promotions--games__item" v-for="(p, index) in promotionData" :key="index"
                    @click="showInfo(p)">
                    <p class="games__item--name">{{ getTranslatedText(p.game_name) }} {{ getTranslatedText(p.game_name_extra) }}</p>
                </div>
                <EmptyScreen v-show="promotionData.length == 0" text="no data" />
            </template>

            <template v-else>
                <div class="promotions--events">
                    <h2 class="promotions--title">{{ getTranslatedText(promotionSelected.game_name) }} {{ getTranslatedText(promotionSelected.game_name_extra) }}</h2>
                    <template v-if="promotionSelected.promotion_type === 'prize-promotion'">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        {{ getTranslatedText("date/time of event") }}
                                    </th>
                                    <th scope="col">{{ getTranslatedText("market") }}</th>
                                    <th scope="col">{{ getTranslatedText("old prize") }}</th>
                                    <th scope="col">{{ getTranslatedText("new prize") }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr :key="index" v-for="(event, index) in promotionSelected.events">
                                    <td>{{ renderDate(event.draw_date) }}</td>
                                    <td>{{ (event.market_name) }}</td>
                                    <td>{{ renderPrice(event.old_prize) }}
                                        {{ getTranslatedText($store.state.userInfo.gamev3.currency_code) }}
                                    </td>
                                    <td>{{ renderPrice(event.new_prize) }}
                                        {{ getTranslatedText($store.state.userInfo.gamev3.currency_code) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                    <template v-else-if="promotionSelected.promotion_type === 'raffle-promotion'">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        {{ getTranslatedText("date/time of event") }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr :key="index" v-for="(event, index) in promotionSelected.events">
                                    <td>{{ renderDate(event.draw_date) }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        {{ getTranslatedText("type of raffle promotion") }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {{ promotionSelected.entry_type.includes('%s') ?
                                            getTranslatedText(`${promotionSelected.entry_type.replace('%s', '[entry_amount]').replace('%s', '[currency]')}`,
                                            {
                                                entry_amount: promotionSelected.entry_amount,
                                                currency: promotionSelected.currency || this.$store.state.userInfo.gamev3.currency_code
                                            }) :
                                            getTranslatedText(promotionSelected.entry_type)
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">{{ getTranslatedText("tiers") }}</th>
                                    <th scope="col">{{ getTranslatedText("prize") }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr :key="index" v-for="(prize, index) in promotionSelected.prizes">
                                    <td>{{ prize.tier }}</td>
                                    <td v-if="promotionSelected.prize_type === 'monetary'">{{ renderPrice(prize.prize) }} {{ promotionSelected.currency || $store.state.userInfo.gamev3.currency_code }}</td>
                                    <td v-else>{{ prize.prize }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </div>

                <ButtonCommon value="back" class-name="promotions--button" :isNoHover="true"
                    @on-click-button-common="back" />
            </template>
        </div>
    </div>
</template>
<script>
import languageMixins from '@/mixins/language/language-mixins'
import PromotionService from '@/services/promotion.service';

import LoadingScreen from '@/components/common/loading/index.vue';
import ButtonCommon from "@/components/common/button/index.vue";
import EmptyScreen from '@/components/common/empty/index.vue';

import { formatDateUTC00, formatPrice } from '@/utils/utils';

export default {
    components: { LoadingScreen, ButtonCommon, EmptyScreen },
    mixins: [languageMixins],
    data() {
        return {
            isLoading: false,
            promotionData: [],
            promotionSelected: null,
        }
    },
    mounted() {
        this.getActivePromotions()
    },
    methods: {
        async getActivePromotions() {
            try {
                this.isLoading = true;
                const resp = await PromotionService.getActivePromotions();
                if (resp.code === 200 && resp.status === "success") {
                    this.promotionData = resp.data
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false;
            }
        },

        showInfo(promotion) {
            this.promotionSelected = promotion;
            // console.log(this.promotionSelected)
        },

        back() {
            this.promotionSelected = null;
        },

        renderDate(date) {
            const dateFormat = formatDateUTC00(date);

            let valueDate = `${dateFormat.day}/${dateFormat.month}/${dateFormat.year} ${dateFormat.hour}H${dateFormat.minutes}`;

            return valueDate;
        },

        renderPrice(price) {
            return price == "" ? "" : formatPrice(price)
        }
    }
}
</script>

<style lang="scss">
@import "~ThemePath/scss/about/promotion.scss";
</style>