import BaseService from './base.service'
import store from '../store/index'
const API_URL = JSON.parse(process.env.VUE_APP_SETTING_JSON).url

class SettingService extends BaseService {
    async fetchSetting(type) {
        let url = API_URL.includes('/cms') ? API_URL.replace('/cms', '') : API_URL
        let date = new Date().valueOf()

        return await this.get(url + `/accounts.json/guinee?v=${date}`).then(({ data }) => {
            localStorage.setItem('SETTING', JSON.stringify(data.guinee))
            return data
        })
    }

    async getAppSetting(type) {
        try {
            this.fetchSetting(type)
            let data = (await localStorage.getItem('SETTING')) || ''
            return JSON.parse(data)
        } catch (e) {
            return {}
        }
    }
}

export default new SettingService()
