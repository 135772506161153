import BaseService from './base.service'

const SETTING_JSON = process.env.VUE_APP_SETTING_JSON || ''

const settings = JSON.parse(SETTING_JSON)

class VoucherService extends BaseService {
    async createVoucher(amount: string) {
        return await this.post(`${settings.url}/api/retail/prepaid-voucher/create`, { amount }).then(({ data }) => data)
    }

    async checkVoucher(voucherCode: string) {
        return await this.post(`${settings.url}/api/retail/prepaid-voucher/check`, { voucherCode }).then(({ data }) => data)
    }

    async payVoucher(voucherCode: string) {
        return await this.post(`${settings.url}/api/retail/prepaid-voucher/pay`, { voucherCode }).then(({ data }) => data)
    }

    async cancelVoucher(voucherCode: string) {
        return await this.post(`${settings.url}/api/retail/prepaid-voucher/cancel`, { voucherCode }).then(({ data }) => data)
    }
}

export default new VoucherService()
