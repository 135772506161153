<template>
    <v-dialog class="dialog" v-model="dialog" origin="top top">
        <v-card>
            <v-card-title class="text-h8">{{ getTranslatedText(title) }}</v-card-title>

            <v-card-text> {{ getTranslatedText(description.replace(/ \(\d+\)/g, '')) }} </v-card-text>

            <v-card-actions>
                <ButtonCommon
                    v-if="isShowCancel"
                    value="cancel"
                    class-name="dialog__box-button__item uppercase"
                    name-function="on-hide-dialog"
                    :isNoHover="true"
                />
                <ButtonCommon
                    value="ok"
                    class-name="dialog__box-button__item uppercase"
                    nameFunction="on-confirm-ok"
                    :isNoHover="true"
                />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import languageMixins from '@/mixins/language/language-mixins'
import EventBus from '@/utils/EventBus'
import ButtonCommon from '@/components/common/button/index.vue'
import { isStrEmpty } from '@/utils/utils'

export default {
    mixins: [languageMixins],
    components: { ButtonCommon },
    data() {
        return {
            dialog: false,
            title: 'Confirmation',
            description: 'Clear all bets?',
            nameFunction: '',
            paramFunction: null,
            urlRedirect: '',
            isShowCancel: true,
        }
    },
    created() {
        EventBus.$on('on-show-dialog', (param) => {
            this.dialog = true
            if(param.isShowCancel === false) {
              this.isShowCancel = param.isShowCancel;
            } else {
              this.isShowCancel = true;
            }
          if (!isStrEmpty(param.title)) this.title = param.title
            if (!isStrEmpty(param.description)) this.description = param.description
            if (!isStrEmpty(param.nameFunction)) this.nameFunction = param.nameFunction
            if (param.paramFunction) this.paramFunction = param.paramFunction
            if (!isStrEmpty(param.urlRedirect)) this.urlRedirect = param.urlRedirect
        })
        EventBus.$on('on-hide-dialog', () => {
            if (!isStrEmpty(this.urlRedirect)) {
                this.$router.push(this.urlRedirect)
            }
            this.dialog = false
        })
        EventBus.$on('on-confirm-ok', () => this.onConfirmOk())
    },
    destroyed() {
        EventBus.$off('on-show-dialog')
        EventBus.$off('on-hide-dialog')
        EventBus.$off('on-confirm-ok')
    },
    methods: {
        onConfirmOk() {
            EventBus.$emit(this.nameFunction, this.paramFunction)
            this.dialog = false
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/common/_dialog.scss';
</style>
