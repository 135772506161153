<template>
    <div class="result">
        <h2 class="report__title">{{ getTranslatedText('results') }}</h2>
        <div v-if="checkShowSelectBox()" class="result__select-games">
            <label>{{ getTranslatedText('select game') }}:</label>
            <b-dropdown id="dropdown-games" :text="this.getTranslatedText(gameSelected)" class="m-md-2">
                <template v-for="(value, index) in dataSelectGames">
                    <b-dropdown-item  :key="index" @click="changeTypeGame(value.value)" v-if="checkShowDropDownItem(index)">
                        {{ getTranslatedText(value.title) }}
                    </b-dropdown-item>
                </template>
            </b-dropdown>
        </div>
        <h2 class="report__description">{{ getTranslatedText(gameSelected) }}</h2>
        <div class="resport__box-date">
            <DatePicker v-if="checkShowGame()" buttonText="select date" :isLoading="isLoadingButton" />
            <ButtonCommon v-if="date !== '' && checkShowGame()" type="primary" className="w100 button__clear" :isNoHover="true" :isLoading="isLoadingButton" value="clear" nameFunction="on-clear-searh-result" />
            <div class="result__button-filters" v-if="gameSelected === 'guinee lotto 6/45'">
                <b-dropdown id="dropdown-date" class="m-md-2" :text="dateSelected">
                    <b-dropdown-item v-for="(value, index) in saturdaysInLastThreeMonths" :key="index" @click="changeDateFilter(value)">
                        {{ value }}
                    </b-dropdown-item>
                </b-dropdown>
                <button @click="changeDateFilter('')" v-if="dateSelected !== this.getTranslatedText('Select date')" class="button button__primary w100 no-hover">
                    {{ getTranslatedText('clear') }}
                </button>
            </div>
        </div>
        <EmptyScreen v-if="dataSource.length === 0" text="no data" />
        <LoadingScreen v-else-if="isLoadingScreen || isLoadingSmall" />
        <template v-else>
            <div class="result__list">
                <details class="result__item" :class="{ hl: i % 2 === 0 }" v-for="(x, i) in dataSource" :key="i" :open="checkOpenDetail()">
                    <summary>
                        <div class="result__item-information">
                            <span class="result__item-text">
                                <span class="hl">{{ getTranslatedText('draw name') }}</span>
                                <span class="text-up">{{ getTranslatedText(x.draw_name) }} </span>
                            </span>
                            <span class="result__item-text">
                                <span class="hl">{{ getTranslatedText('draw Date') }}</span>
                                {{ renderDate(x.draw_date) }}
                            </span>
                        </div>
                        <div class="result__item-results">
                            <span class="result__item-text">
                                <span class="hl">{{ getTranslatedText('results') }}</span>
                            </span>
                            <div class="result__box-number" v-for="(itemResults, index) in x.results" :key="index">
                                <span v-if="index > 0" class="result__item-text plus">+</span>
                                <div class="result__number" v-for="(number, numberIndex) in itemResults" :key="numberIndex">{{ number }}</div>
                            </div>
                        </div>
                    </summary>
                    <table class="table-results__table-tiers" v-if="x.tiers && x.tiers.length > 0">
                        <thead>
                            <tr>
                                <th class="table-results__table-tiers__name">{{ getTranslatedText('Match Numbers') }}</th>
                                <th class="table-results__table-tiers__winners">{{ getTranslatedText('Winners') }}</th>
                                <th class="table-results__table-tiers__payouts">{{ getTranslatedText('Payout') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(tier, i) in x.tiers" :key="i">
                                <td>{{ tier.name }}</td>
                                <td>{{ renderPrice(tier.winners, 0) }}</td>
                                <td>{{ renderPrice(tier.prize, 2) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </details>
            </div>

            <ButtonCommon v-if="date !== ''" className="w100 result__button-print" :isNoHover="true" :isLoading="isLoadingButton" value="print" nameFunction="on-print-result" />
            <div class="result__pagination" :class="{ center: current > 1 || currentTottal > current }" v-if="dataApi.length > 10">
                <img v-if="current > 1" src="~ThemePath/images/icons-double-left.png/" @click="() => onPagination(1)" />
                <img v-if="currentTottal > current" class="result__pagination-next" src="~ThemePath/images/icons-double-left.png/" @click="() => onPagination(2)" />
            </div>
        </template>
    </div>
</template>
<script>
import languageMixins from '@/mixins/language/language-mixins'
import printMixins from '@/mixins/print/print-mixin'
import EventBus from '@/utils/EventBus'
import EmptyScreen from '@/components/common/empty/index.vue'
import DatePicker from '@/components/common/datepicker/index.vue'
import LoadingScreen from '@/components/common/loading/index.vue'
import ButtonCommon from '@/components/common/button/index.vue'

import { formatDateUTC00, formatPrice, isStrEmpty } from '@/utils/utils'

export default {
    mixins: [languageMixins, printMixins],
    components: { DatePicker, LoadingScreen, EmptyScreen, ButtonCommon },
    props: {
        dataSource: {
            type: Array,
            default: () => [],
        },
        dataApi: {
            type: Array,
            default: () => [],
        },
        date: {
            type: String,
            default: '',
        },
        current: {
            type: Number,
            default: 1,
        },
        currentTottal: {
            type: Number,
            default: 0,
        },
        isLoadingSmall: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        saturdaysInLastThreeMonths() {
            const now = new Date()
            const threeMonthsAgo = new Date(now.getFullYear(), now.getMonth() - 1, 1)
            const saturdays = []

            // Loop through all days in the past 3 months
            for (let d = threeMonthsAgo; d <= now; d.setDate(d.getDate() + 1)) {
                // Check if current day is a Saturday

                if (d.getDay() === 6) {
                    let saturday = new Date(d.getTime()).toLocaleDateString('en-GB')
                    let draw645 = localStorage.getItem('draw-645')
                    
                    if(!isStrEmpty(draw645)) {
                        const timeFormat = formatDateUTC00(draw645)
                        saturday += ` ${timeFormat.hour}H${timeFormat.minutes}`
                    }
                    
                    saturdays.push(saturday)
                }
            }
            saturdays.reverse()
            return saturdays
        },
    },
    data() {
        return {
            dateSelected: this.getTranslatedText('Select date'),
            isLoadingScreen: false,
            isLoadingButton: false,
            typeGame: 'multi-lotto',
            gameSelected: 'guinee lotto 5/90',
            dataSelectGames: [
                {
                    title: 'guinee lotto 5/90',
                    value: 'guinee lotto 5/90',
                },
                {
                    title: 'guinee lotto 6/45',
                    value: 'guinee lotto 6/45',
                },
                {
                    title: 'loto 4/59',
                    value: 'loto 4/59',
                },
                {
                    title: 'loto 5/45',
                    value: 'loto 5/45',
                },
            ],
        }
    },
    created() {
        let gameSelected = this.$store.state.isShow590 ? 'guinee lotto 5/90' : 'guinee lotto 6/45'
        this.gameSelected = gameSelected
        EventBus.$on('on-change-isloading-result', (param) => {
            this.isLoadingScreen = param.isLoadingScreen
            this.isLoadingButton = param.isLoadingButton
        })
        EventBus.$on('on-print-result', () => this.onPrintResult())
    },
    destroyed() {
        EventBus.$off('on-change-isloading-result')
        EventBus.$off('on-print-result')
    },
    methods: {
        checkOpenDetail() {
            return this.dateSelected !== this.getTranslatedText('Select date')
        },
        renderPrice(price, minFormat) {
            return formatPrice(price, minFormat)
        },
        changeDateFilter(value) {
            if (value) {
                this.dateSelected = value
            } else {
                this.dateSelected = this.getTranslatedText('Select date')
            }
            this.$emit('changeDateFilter', value)
        },
        formatDateTime(date) {
            const firstIndex = date.indexOf(':')
            const secondIndex = date.indexOf(':', firstIndex + 1)
            return date.substring(0, secondIndex)
        },
        renderDate(date) {
            const dateFormat = formatDateUTC00(date)

            let valueDate = `${dateFormat.day}/${dateFormat.month}/${dateFormat.year} ${dateFormat.hour}:${dateFormat.minutes}`

            return valueDate
        },
        onPagination(type) {
            this.isLoadingScreen = true
            this.isLoadingButton = true
            EventBus.$emit('on-pagination-result', type)
        },
        onPrintResult() {
            this.isLoadingButton = true
            let dataPrints = this.dataApi.map(x => {
                let game = this.getTranslatedText(x.game)
                let draw_name = this.getTranslatedText(x.draw_name)

                let item = {
                    ...x,
                    game,
                    draw_name
                }
                
                return item
            })

            parent.postMessage({ command: 'print-result', param: dataPrints }, '*')
            this.printResult(dataPrints)
            this.isLoadingButton = false
        },
        changeTypeGame(value) {
            this.gameSelected = value
            this.$emit('typeGame', value)
            EventBus.$emit('on-change-default-datepicker', '')
        },

        checkShowGame() {
            const isEnabelDatePicker = this.gameSelected === 'guinee lotto 5/90' || this.gameSelected === 'loto 4/59' || this.gameSelected === 'loto 5/45'
            return isEnabelDatePicker
        },

        checkShowSelectBox() {
            const {isShow590, isShow645, isShow459, isShow545} = this.$store.state
            const trueCount = [isShow590, isShow645, isShow459, isShow545].filter(Boolean).length;
            return trueCount >= 2;
        },

        checkShowDropDownItem(index) {
            const {isShow590, isShow645, isShow459, isShow545} = this.$store.state

            if(index === 0 && isShow590) return true
            if(index === 1 && isShow645) return true
            if(index === 2 && isShow459) return true
            if(index === 3 && isShow545) return true
        }
    },
}
</script>
<style lang="scss">
@import '~ThemePath/scss/result/_index.scss';
</style>
