import { getSettingDevice, isStrEmpty } from '@/utils/utils'
import axiosLib from 'axios'
import store from '../store';

const axiosAuth2 = axiosLib.create()

// axiosAuth2.interceptors.request.use((config) => {
//     config.timeout = 30000
//     return config
// })

axiosAuth2.interceptors.request.use((request) => {
    const timeoutSeconds = store?.state?.networkTimeoutSeconds ?? 30
    request.timeout = timeoutSeconds * 1000

    const token = process.env.VUE_APP_TOKEN
    let setting: any = getSettingDevice()

    if (token) {
        request.headers.Authorization = token
        request.headers.ContentType = 'application/json'
    }

    if (!isStrEmpty(setting)) {
        setting = JSON.parse(setting)
        if (!setting.position) setting.position = {}
        setting.position.error_message = setting.location_error
        request.headers['Device-Network-Mac-Address'] = setting.mac
        request.headers['Device-Serial-Number'] = setting.serial
        request.headers['Device-Unique-Id'] = setting.uniqueId
        request.headers['App-Version'] = setting.appVersion
        request.headers['Device-Position'] = JSON.stringify(setting.position)
    }

    return request
})

axiosAuth2.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
            return Promise.reject('ECONNABORTED')
        }

        if (error.response && error.response.data) {
            return Promise.reject(error.response.data)
        }
        return Promise.reject(error.message)
    }
)

export default axiosAuth2
