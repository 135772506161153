<template>
    <div class="select-pick">
        <p class="select-pick__title">{{ getTranslatedText(`quick selection`) }}</p>
        <div class="select-pick__button">
            <ButtonCommon v-for="(n, index) in rangePickNumber" :key="index" :isNoHover="false" :value="n.toString()"
                className="screen-number__btn-quick-pick" @on-click-button-common="selectPick(n)" />
        </div>
    </div>
</template>
<script>
import ButtonCommon from "@/components/common/button/index.vue";
import languageMixins from "@/mixins/language/language-mixins";
export default {
    mixins: [languageMixins],
    components: { ButtonCommon },
    props: {
        rangePickNumber: []
    },
    methods: {
        selectPick(pick) {
            this.$emit('select-pick', pick)
        }
    }
}
</script>

<style lang="scss">
@import "~ThemePath/scss/games/_select_pick.scss";
</style>