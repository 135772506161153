<template>
    <div class="app-select">
        <p class="app-select__label">{{ getTranslatedText('Select the event') }}</p>
        <!--        <select class="app-select__list" v-model="selected" @change="onChangeSelect">-->
        <!--            <option class="app-select__item" value="all">{{ getTranslatedText('SELECT ALL') }}</option>-->
        <!--            <option class="app-select__item" v-for="(option, index) in dataOption" :key="index" :value="option.event_id">{{ option.draw_name }}</option>-->
        <!--        </select>-->
        <p class="app-select__text">{{ getTranslatedText('current jackpot') }}: {{ formatPrice(currentJackpot) }}</p>
    </div>
</template>
<script>
import EventBus from '@/utils/EventBus'
import languageMixins from '@/mixins/language/language-mixins'

import { isStrEmpty, formatPrice } from '@/utils/utils'

export default {
    mixins: [languageMixins],
    props: {
        dataOption: {
            type: Array,
            default: () => [],
        },
        nameFunction: {
            type: String,
            default: '',
        },
        currentJackpot: null,
    },
    data() {
        return {
            selected: 'all',
        }
    },
    methods: {
        onChangeSelect() {
            if (!isStrEmpty(this.nameFunction)) {
                EventBus.$emit(this.nameFunction, this.selected)
            }
        },

        formatPrice(price) {
            return formatPrice(parseFloat(price))
        }
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/games/_select.scss';
</style>
