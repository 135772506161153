<template>
    <v-main>
        <LoadingScreen v-if="isLoadingScreen" />
        <template v-if="!isLoadingScreen">
            <div class="result">
                <h2 class="report__title">{{ getTranslatedText('promotion results') }}</h2>
                <div class="result__select-games" v-if="promotions && promotions.length > 0">
                    <label>{{ getTranslatedText('select promotion') }}:</label>
                    <b-dropdown id="dropdown-promotion" :text="getSelectName(selectedPromotionId)" class="m-md-2">
                        <template v-for="( value, index ) in  promotions ">
                            <b-dropdown-item :key="index" @click="promotionChanged(value.promotion_id)">
                                {{ getSelectName(value.promotion_id) }}
                            </b-dropdown-item>
                        </template>
                    </b-dropdown>
                </div>
                <EmptyScreen v-if="!promotions || promotions.length === 0" text="no data" />
                <LoadingScreen v-else-if="isLoadingScreen || isLoadingSmall" />
                <template v-else>
                    <div class="result__list">
                        <details class="result__item" :class="{ hl: i % 2 === 0 }" v-for="( x, i ) in  prizes "
                            :key="i">
                            <summary>
                                <div class="result__item-information">
                                    <span class="result__item-text">
                                        <span class="hl">{{ getTranslatedText('tier') }}:</span>
                                        {{ x.tier }}
                                    </span>
                                    <span class="result__item-text">
                                        <span class="hl">{{ getTranslatedText('prize amount') }}:</span>
                                        {{ selectedPromotion.prize_slug === 'monetary' ? renderPrice(x.winnings) : x.winnings }}
                                        {{ selectedPromotion.prize_slug === 'monetary' ? currency : "" }}
                                    </span>
                                </div>
                                <div class="result__item-results">
                                    <span class="result__item-text">
                                        <span class="hl">{{ getTranslatedText('winning code') }}:</span>
                                        {{ x.transaction_id }}
                                    </span>
                                </div>
                            </summary>
                        </details>
                    </div>

                </template>
            </div>
        </template>
    </v-main>
</template>

<script>
import ResultService from '@/services/result.service'
import LoadingScreen from '@/components/common/loading/index.vue'
import EventBus from '@/utils/EventBus'
import { formatPrice } from '@/utils/utils'
import languageMixins from '@/mixins/language/language-mixins'
import EmptyScreen from '@/components/common/empty/index.vue'
import ButtonCommon from '@/components/common/button/index.vue'
const CURRENCY = process.env.VUE_APP_CURRENCY
export default {
    mixins: [languageMixins],
    components: { LoadingScreen, EmptyScreen, ButtonCommon },
    data() {
        return {
            promotions: [],
            selectedPromotionId: null,
            selectedPromotion: null,
            prizes: null,
            isLoadingScreen: true,
            isLoadingSmall: false,
            currency: CURRENCY
        }
    },

    created() {
        this.fetchData()
    },

    destroyed() {
    },

    methods: {
        getSelectName(id) {
            return this.getTranslatedText('Promotion ID') + " " + id;
        },

        promotionChanged(p) {
            this.selectedPromotionId = p
            this.selectedPromotion = this.promotions.find(obj => {
                return obj.promotion_id === this.selectedPromotionId
            })
            if (this.selectedPromotion) {
                this.prizes = this.selectedPromotion.prizes
            }
        },

        renderPrice(price) {
            return formatPrice(price)
        },

        async fetchData() {
            try {
                const paramLoading = {
                    isLoadingScreen: false,
                    isLoadingButton: false,
                }
                const resp = await ResultService.getPromotionResult()
                if (resp.code === 200) {
                    this.promotions = resp.data
                    if (this.promotions && this.promotions.length > 0) {
                        this.promotionChanged(this.promotions[0].promotion_id)
                    }
                    this.isLoadingScreen = false
                    this.isLoadingSmall = false

                    EventBus.$emit('on-change-isloading-result', paramLoading)
                } else {
                    this.isLoadingScreen = false
                    this.isLoadingSmall = false
                    const param = {
                        text: resp.message,
                        type: 'error',
                    }
                    EventBus.$emit('show-notications', param)
                    EventBus.$emit('on-change-isloading-result', paramLoading)
                }
            } catch (error) {
                console.log(error)
                this.isLoadingScreen = false
                this.isLoadingSmall = false
            }
        },

    },
}
</script>
