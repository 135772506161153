import BaseService from './base.service'
import store from '@/store/index'

const SETTING_JSON = process.env.VUE_APP_SETTING_JSON || ''

const settings = JSON.parse(SETTING_JSON)

let userInfo: any = store.state.userInfo
interface reportInterface {
    from: string
    to: string
}

class ReportService extends BaseService {
    async report(param: reportInterface) {
        const { from, to } = param
        return await this.get(`${settings.url}/api/retail/report/report-by-cashier-and-game?admin_user_id=${userInfo?.details?.id}&from=${from}&to=${to}`).then(({ data }) => data)
    }

    async makeAFinalReport() {
        let response = await this.post(`${settings.url}/api/retail/cashier/self-lock`, '')
        return response.data
    }
}

export default new ReportService()
