<template>
    <v-dialog persistent class="dialog" v-model="dialog" origin="top top">
        <v-card class="dialog-rebet__container">
            <v-card-title class="text-h8 dialog-rebet__title"><span class="dialog-rebet__title__red">{{ getTranslatedText('attention') }}</span>
                <!-- <span>&nbsp;-&nbsp;</span> -->
                <span v-if="reprintCount < reprintCountToShowCancel">{{ getTranslatedText("your transaction has been confirmed") }}</span>
                <span v-if="reprintCount >= reprintCountToShowCancel">{{ getTranslatedText("your ticket has been reprinted") }}</span>
            </v-card-title>

            <v-card-text v-if="reprintCount < reprintCountToShowCancel" class="dialog-rebet__description">{{ getTranslatedText("please press \"yes\" to confirm whether the ticket has been printed, or \"no\" to reprint the ticket.".replace(/ \(\d+\)/g, "")) }}</v-card-text>
            <v-card-text v-if="reprintCount >= reprintCountToShowCancel" class="dialog-rebet__description">{{ getTranslatedText("please press \"yes\" to confirm whether the ticket has been printed, or \"no\" to cancel the ticket.".replace(/ \(\d+\)/g, "")) }}</v-card-text>

            <v-card-actions>
                <ButtonCommon v-if="reprintCount >= reprintCountToShowCancel" value="non - cancel"
                    class-name="dialog__box-button__item uppercase" :isNoHover="true"
                    :isLoading="isUpdating || isDevicePrinting" name-function="on-print-cancel" />
                <ButtonCommon v-if="reprintCount < reprintCountToShowCancel" value="non - reprint"
                    class-name="dialog__box-button__item uppercase" :isNoHover="true"
                    :isLoading="isUpdating || isDevicePrinting" name-function="on-print-reprint" />
                <ButtonCommon value="yes" class-name="dialog__box-button__item uppercase" :isNoHover="true"
                    :isLoading="isUpdating || isDevicePrinting" nameFunction="on-print-ok" />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import languageMixins from "@/mixins/language/language-mixins";
import printMinxin from '@/mixins/print/print-mixin'
import EventBus from "@/utils/EventBus";
import ButtonCommon from "@/components/common/button/index.vue";
import BetslipsService from '@/services/betslip.service'
import { isArrayEmpty } from '@/utils/utils'
import Vue from 'vue'

export default {
    mixins: [languageMixins, printMinxin],
    components: { ButtonCommon },
    data() {
        let data =
        {
            dialog: false,
            reference: "",
            ticketType: "",
            isUpdating: false,
            isDevicePrinting: true,
            printStatusMessage: '',
            paramPrint: null,
            userInfo: null,
            reprintCount: 0,
            shouldIncreasePrintedCount: false,
            reprintCountToShowCancel: this.$store.state.reprintCountToShowCancel ?? 3,
            milisecondsToForceFinishPrinting: this.$store.state.milisecondsToForceFinishPrinting ?? 4000,
            forSport: false,
            forLotto: false
        };
        return data;
    },
    mounted() {
        document.addEventListener('message', this.handlePrintMessageFromApk)
    },
    created() {
        EventBus.$on("on-show-reprint", (param) => {
            this.isDevicePrinting = true
            this.shouldIncreasePrintedCount = false
            this.reprintCount = 0
            Vue.prototype.$isReprintDialogShowing = true
            this.reference = param.reference;
            this.betslipId = param.betslipId;
            this.ticketType = param.ticketType;
            this.paramPrint = param.paramPrint;
            this.userInfo = param.userInfo;
            this.dialog = true;
            this.forSport = param.forSport;
            this.forLotto = param.forLotto;

            this.initTimeoutToForceFinishPrinting()
        });

        // actions
        EventBus.$on("on-print-ok", () => this.updatePrintingStatus());
        EventBus.$on("on-print-cancel", () => this.cancelBetslip());
        EventBus.$on("on-print-reprint", () => this.reprint());
    },
    beforeDestroy() {
        window.removeEventListener('message', this.handlePrintMessageFromApk)
    },
    destroyed() {
        EventBus.$off("on-show-reprint");

        EventBus.$off("on-print-ok");
        EventBus.$off("on-print-cancel");
        EventBus.$off("on-print-reprint");
    },
    methods: {
        handlePrintMessageFromApk(event) {
            const resp = JSON.parse(event.data)
            if (resp.command === 'print-status') {
                if (resp.code === 200) {
                    this.printStatusMessage = 'ok'
                } else {
                    // error from apk
                    this.printStatusMessage = 'not ok'
                }
                if (this.shouldIncreasePrintedCount === true) {
                    this.shouldIncreasePrintedCount = false
                    this.reprintCount++;
                }
                this.isDevicePrinting = false
            }
        },

        async updatePrintingStatus() {
            if (this.isUpdating === true) return;

            // todo: update ui, not allow to call api when device is not finish printing (OPTIONAL)

            this.isUpdating = true;

            let res = await BetslipsService.updatePrintingStatus(this.ticketType, this.reference, this.reprintCount)

            // todo: need to check response?

            this.isUpdating = false;
            this.dialog = false;

            this.shouldIncreasePrintedCount = false
            this.reprintCount = 0
            this.isDevicePrinting = false

            Vue.prototype.$isReprintDialogShowing = false
        },

        async cancelBetslip() {
            if (this.isUpdating === true) return;
            this.isUpdating = true;

            try {
                const resp = await BetslipsService.cancelBetslip(this.betslipId)
                if (resp.status === 200) {
                    let message = ''
                    !isArrayEmpty(resp.errors)
                        ? resp.errors.forEach((element) => {
                            message += element.message
                        })
                        : (message = resp.message)
                    const param = {
                        text: message ? message : 'cancel successfully',
                        type: 'success',
                    }
                    EventBus.$emit('show-notications', param)

                    // go to home screen
                    this.dialog = false
                    Vue.prototype.$isReprintDialogShowing = false
                    this.$router.push('/')
                } else {
                    let message = ''
                    !isArrayEmpty(resp.errors)
                        ? resp.errors.forEach((element) => {
                            message += element.message
                        })
                        : (message = resp.message)
                    const param = {
                        text: message,
                        type: 'error',
                        className: 'up-first'
                    }
                    EventBus.$emit('show-notications', param)
                }
            } catch (error) {
                console.log(error)
                const param = {
                    text: 'System error',
                    type: 'error',
                    className: 'up-first'
                }
                EventBus.$emit('show-notications', param)
            }

            this.isUpdating = false;
        },

        async reprint() {
            // this.reprintCount++;
            this.shouldIncreasePrintedCount = true;
            this.isDevicePrinting = true
            this.initTimeoutToForceFinishPrinting()

            if (this.forSport === true) {
                parent.postMessage({ command: 'print-sports', param: this.paramPrint }, '*')
                this.printSports(this.paramPrint, true)
            }

            if (this.forLotto === true) {
                parent.postMessage({ command: 'print-rebet-ticket', param: this.paramPrint }, '*')
                this.printLoto(this.paramPrint, true)
            }
        },

        initTimeoutToForceFinishPrinting() {
            setTimeout(() => {
                if (this.shouldIncreasePrintedCount === true) {
                    this.shouldIncreasePrintedCount = false
                    this.reprintCount++;
                }
                this.isDevicePrinting = false
            }, this.milisecondsToForceFinishPrinting);
        }
    },
};
</script>

<style lang="scss">
@import "~ThemePath/scss/common/_dialog-rebet.scss";
</style>
