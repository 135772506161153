<template>
    <div class="events games-multi container-screen">
        <LoadingScreen v-if="loadingPage" />
        <template v-else>
            <div class="events__body">
                <TitleCommon :value="`${getTranslatedText(this.dataGames459?.game?.name || 'Loto 4/59')}`" />
                <template v-if="dataSoureDrawWeek.length > 0 || dataSourceFilter.length > 0">
                    <SelectFilter :dataOption="dataSource" nameFunction="on-filter-events-459" :currentJackpot="dataGames459?.game?.estimated_jackpot"/>
                    <div class="loto__list__head__button events__butoon-select">
                        <button @click="onSelectAll">{{ getTranslatedText("Select All") }}</button>
                    </div>
                    <div class="events__content">
                        <div class="events__list">
                            <h3 v-show="dataSoureDrawWeek.length > 0" class="event__list-title">{{ getTranslatedText("draw this week") }}</h3>
                            <div class="events__item g459 same-week" :class="{ active: eventSelect.includes(v.event_id), mb: dataSoureDrawWeek.length === i + 1 }" v-for="(v, i) in dataSoureDrawWeek" :key="v.draw_date" @click="onSelectEvent(v.event_id, v)">
                                <span class="events__item-date">{{ renderDayOfWeek(v.draw_date) }} {{ renderDate(v.draw_date) }}</span>
                            </div>
                            <h3 v-show="dataSourceFilter.length > 0" class="event__list-title">{{ getTranslatedText("next draws") }}</h3>
                            <div class="events__item g459" :class="{ active: eventSelect.includes(v.event_id) }" v-for="(v, i) in dataSourceFilter" :key="i" @click="onSelectEvent(v.event_id, v)">
                                <span class="events__item-date">{{ renderDayOfWeek(v.draw_date) }} {{ renderDate(v.draw_date) }}</span>
                            </div>
                        </div>
                        <div class="events_box-button">
                            <button @click="$router.push('/')" class="button button__active no-hover">
                                {{ getTranslatedText("back") }}
                            </button>
                            <ButtonCommon type="error" :isNoHover="true" :class-name="'button__continue g459'" value="continue" :isLoading="isLoadingButton" @on-click-button-common="onEventContinue" />
                        </div>
                    </div>
                </template>
                <div class="events__empty" v-else>{{ getTranslatedText('empty game') }}</div>
            </div>
        </template>
    </div>
</template>

<script>
import { formatDateUTC00, isArrayEmpty, isCurrentWeek, getDayOfWeek } from "@/utils/utils";

import GamesService from "@/services/games.service";
import pathAssetsMixins from "@/mixins/pathAssets/path-assets-mixins";
import languageMixins from "@/mixins/language/language-mixins";
import EventBus from "@/utils/EventBus";
import ButtonCommon from "@/components/common/button/index.vue";
import LoadingScreen from "@/components/common/loading/index.vue";
import TitleCommon from "@/components/common/title/index.vue";
import SelectFilter from "@/components/games/events/select/index.vue";

export default {
    mixins: [pathAssetsMixins, languageMixins],
    components: { ButtonCommon, SelectFilter, LoadingScreen, TitleCommon },
    data() {
        return {
            dataSource: [],
            dataSoureDrawWeek: [],
            dataSourceFilter: [],
            dataSourceGames: null,
            eventSelect: [],
            eventInfo: [],
            loadingPage: true,
            isLoadingButton: false,
            dataGames459: [],
        };
    },
    mounted() {
        this.fetchData();
    },
    created() {
        EventBus.$on("on-filter-events-459", (param) => this.onFilterEvents(param));
    },
    destroyed() {
        EventBus.$off("on-filter-events-459");
    },
    methods: {
        async fetchData() {
            try {
                const resp = await GamesService.getListGames("pari-mutuel-lotto");
                const respEvent = await GamesService.getListEvents("pari-mutuel-lotto");
                if (!isArrayEmpty(resp.data) && !isArrayEmpty(respEvent.data)) {
                    const data459 = resp.data.filter((x) => x.game.slug === "loto-4-59");
                    const dataEvent459 = respEvent.data.filter((x) => x.game.toLowerCase() === "loto 4/59");
                    this.dataSoureDrawWeek = dataEvent459.filter((x) => this.isCheckDrawThisWeek(x.draw_date));
                    const lengthData = this.dataSoureDrawWeek.length > 0 ? 7 : 8;

                    this.dataSourceFilter = dataEvent459.filter((x) => !this.isCheckDrawThisWeek(x.draw_date)).slice(0, lengthData);

                    this.dataSource = dataEvent459.splice(0, lengthData + 1);
                    this.dataGames459 = data459[0];

                    if (!isArrayEmpty(respEvent.data)) {
                        localStorage.setItem("draw-459", JSON.stringify(this.dataSource[0]))
                        localStorage.setItem("jackpot-459", data459[0].game?.estimated_jackpot)
                    }  

                    this.loadingPage = false;
                }
            } catch (error) {
                this.loadingPage = false;
                console.log(error);
            }
        },

        onSelectAll() {
            if (this.dataSource.length === this.eventSelect.length) {
                this.eventSelect = [];
                this.eventInfo = [];
            } else {
                this.dataSource.forEach((item) => {
                    const index = this.eventSelect.findIndex((x) => x === item.event_id);
                    if (index === -1) {
                        this.eventSelect.push(item.event_id);
                        this.eventInfo.push({
                            name: item.draw_name,
                            date: item.draw_date,
                        });
                    }
                });
            }
        },

        onSelectEvent(eventId, item) {
            const index = this.eventSelect.findIndex((x) => x === eventId);
            if (index !== -1) {
                this.eventSelect.splice(index, 1);
                this.eventInfo.splice(index, 1);
            } else {
                this.eventSelect.push(eventId);
                this.eventInfo.push({
                    name: item.draw_name,
                    date: item.draw_date,
                });
            }
        },

        isCheckDrawThisWeek(date) {
            return isCurrentWeek(date);
        },

        renderDate(date) {
            const dateFormat = formatDateUTC00(date);

            let valueDate = `${dateFormat.day}/${dateFormat.month}/${dateFormat.year} ${dateFormat.hour}H${dateFormat.minutes}`;

            return valueDate;
        },
        onFilterEvents(value) {
            let dataSourceFilter = [...this.dataSource];
            if (value !== "all") {
                dataSourceFilter = this.dataSource.filter((x) => x.event_id === value);
            }
            this.dataSourceFilter = dataSourceFilter;
        },
        onEventContinue() {
            if (isArrayEmpty(this.eventSelect)) {
                const param = {
                    text: "Please choose the game",
                    type: "warning",
                };
                EventBus.$emit("show-notications", param);

                return false;
            } else {
                const maxPicks = this.dataGames459.game?.config?.setup[1].maxPicks;
                const minPicks = this.dataGames459.game?.config?.setup[1].picks;
                let param = {
                    title: this.dataGames459.game?.name,
                    game: this.dataGames459.game?.slug,
                    gameNumber: this.dataGames459.game?.game_id,
                    maxPicks: maxPicks,
                    minPicks: minPicks,
                    numberQp: minPicks,
                    event_id: this.eventSelect,
                    eventInfo: this.eventInfo,
                    selection: [],
                    draws: 1,
                    wager: this.dataGames459.game?.price,
                    isPariMutuelLotto: true,
                    gameType: this.dataGames459.game.slug,
                };
                localStorage.setItem("loto459-event", JSON.stringify(param));
                this.$router.push("/loto-459/numbers");
            }
        },

        renderDayOfWeek(drawDate) {
            const dayOfWeek = getDayOfWeek(drawDate);
            return this.getTranslatedText(dayOfWeek);
        },
    },
};
</script>

<style lang="scss">
@import "~ThemePath/scss/games/_event.scss";
</style>
