<template>
    <div class="report">
        <h2 class="report__title">{{ isFinal ? getTranslatedText('REPORT FINal') : getTranslatedText('REPORT') }}</h2>
        <div v-if="!isFinal" class="report__box-datepicker">
            <DatePicker buttonText="From" keyValue="from" :maxDate="dateTo" />
            <DatePicker buttonText="To" keyValue="to" :minDate="dateFrom" />
        </div>
        <EmptyScreen v-if="(isEmpty && !isLoadingScreen) || !dataSource" text="no data" />
        <LoadingScreen v-else-if="isLoadingScreen" />
        <template v-else>
            <div class="report__table">
                <span class="report__table-title">{{ valueDate }}</span>
                <LoadingScreen v-if="isLoadingScreen" />
                <template v-else>
                    <div class="report__table-item">
                        <span class="report__table-item-text report__table-column-label">
                            {{ getTranslatedText('Summary') }}
                        </span>
                    </div>
                    <div class="report__table-item">
                        <span class="report__table-item-text report__table-column-label">{{ getTranslatedText('cash deposits') }}</span>
                        <span class="report__table-item-text report__table-column-value">{{ dataSource.currency }} {{ renderPrice(dataSource.cash_deposits) }} </span>
                    </div>
                    <div class="report__table-item">
                        <span class="report__table-item-text report__table-column-label">{{ getTranslatedText('cancellations') }}</span>
                        <span class="report__table-item-text report__table-column-value">{{ dataSource.currency }} {{ renderPrice(dataSource.refunded_cancelations) }} </span>
                    </div>
                    <div class="report__table-item">
                        <span class="report__table-item-text report__table-column-label">{{ getTranslatedText('payouts') }}</span>
                        <span class="report__table-item-text report__table-column-value">{{ dataSource.currency }} {{ renderPrice(dataSource.payouts) }} </span>
                    </div>
                    <div class="report__table-item" v-if="this.dateFrom != this.dateTo">
                        <span class="report__table-item-text report__table-column-label">{{ getTranslatedText('float adjustments') }} </span>
                        <span class="report__table-item-text report__table-column-value">{{ dataSource.currency }} {{ renderPrice(dataSource.float_adjustments) }} </span>
                    </div>
                    <div class="report__table-item">
                        <span class="report__table-item-text report__table-column-label">{{ getTranslatedText('true balance') }}</span>
                        <span class="report__table-item-text report__table-column-value">{{ dataSource.currency }} {{ renderTrueBalance()  }} </span>
                    </div>
                    <div class="report__table-item-data-report">
                        <template v-for="(x, i) in dataReport">
                            <div class="report__table-item-data-report-item" :class="{ odd: i % 2 === 0, even: i % 2 !== 0 }"  :key="i" v-if="x.product_type && x.product_type !== ''">
                                <div class="report__table-item">
                                    <span class="report__table-item-text report__table-column-label">{{ getTranslatedText(x.product_type) }}</span>
                                </div>
                                <div class="report__table-item">
                                    <span class="report__table-item-text report__table-column-label">{{ getTranslatedText('wagers') }}</span>
                                    <span class="report__table-item-text report__table-column-value">{{ x.currency }} {{ renderPrice(x.wagers) }} </span>
                                </div>
                                <div class="report__table-item">
                                    <span class="report__table-item-text report__table-column-label">{{ getTranslatedText('payouts') }}</span>
                                    <span class="report__table-item-text report__table-column-value">{{ x.currency }} {{ renderPrice(x.payouts) }} </span>
                                </div>
                            </div>
                        </template>
                    </div>
                </template>
            </div>
            <div class="report__box-button">
                <div class="report__box-button-header">
                    <ButtonCommon v-if="!isFinal" className="w100" :isNoHover="true" :isLoading="isLoadingButton" value="close" nameFunction="on-click-report-close" type="primary" />
                    <ButtonCommon className="w100" :isNoHover="true" :isLoading="isLoadingButton" value="print" nameFunction="on-print-report" />
                </div>
                <ButtonCommon className="w100" :isNoHover="true" :isLoading="isLoadingButton" value="back" nameFunction="on-click-report-back" />
            </div>
        </template>
    </div>
</template>
<script>
import languageMixins from '@/mixins/language/language-mixins'
import printMixins from '@/mixins/print/print-mixin'
import ButtonCommon from '@/components/common/button/index.vue'
import DatePicker from '@/components/common/datepicker/index.vue'
import LoadingScreen from '@/components/common/loading/index.vue'
import EmptyScreen from '@/components/common/empty/index.vue'
import EventBus from '@/utils/EventBus'

import { formatDate, formatPrice } from '@/utils/utils'
import reportService from '@/services/report.service'

export default {
    mixins: [languageMixins, printMixins],
    components: { ButtonCommon, DatePicker, LoadingScreen, EmptyScreen },
    props: {
        dataSource: {
            type: Object,
            default: () => {},
        },
        dataReport: {
            type: Array,
            default: () => null,
        },
        cashier: {
            type: Object,
            default: () => {},
        },
        isFinal: {
            type: Boolean,
            default: true,
        },
        dateTo: {
            type: String,
            default: '',
        },
        dateFrom: {
            type: String,
            default: '',
        },
        valueDate: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isLoadingScreen: false,
            isEmpty: false,
            isLoadingButton: false,
        }
    },
    created() {
        EventBus.$on('change-is-loading-report', (isEmpty) => {
            this.isLoadingScreen = false
            this.isLoadingButton = false
            this.isEmpty = isEmpty
        })
        EventBus.$on('select-date-picker', () => {
            this.isLoadingScreen = true
            this.isLoadingButton = true
        })
        EventBus.$on('on-click-report-back', () => this.$router.go(-1))
        EventBus.$on('on-click-report-close', () => this.$router.push('/'))
        EventBus.$on('on-print-report', () => (this.$route.meta.isFinal ? this.onCheckPrintReportFinal() : this.onPrintReport()))
        EventBus.$on('on-make-a-final-report', () => this.onMakeAFinalReport())
    },
    destroyed() {
        EventBus.$off('change-is-loading-report')
        EventBus.$off('select-date-picker')
        EventBus.$off('on-click-report-back')
        EventBus.$off('on-click-report-close')
        EventBus.$off('on-print-report')
        EventBus.$off('on-make-a-final-report')
    },
    methods: {
        async onMakeAFinalReport() {
            try {
                this.isLoadingButton = true
                const resp = await reportService.makeAFinalReport()
                if (resp.status === 200) {
                    this.onPrintReport()
                    this.$router.go(-1)
                }
                else this.$router.go(-1)
            } catch (error) {
                console.log(error)
            }
        },
        onCheckPrintReportFinal() {
            if (this.cashier) this.cashier.title = 'Final report of'
            if (this.cashier && this.cashier.locked === true) {
                this.onPrintReport()
                return false
            } else if (this.cashier) {
                const param = {
                    description: 'confirm final print',
                    nameFunction: 'on-make-a-final-report',
                    urlRedirect: '/',
                }
                EventBus.$emit('on-show-dialog', param)
            } else {
                this.$router.go(-1)
            }
        },
        onPrintReport() {
            this.isLoadingButton = true
            let valueDate = this.valueDate

            const param = {
                cashier: this.cashier,
                sum: this.dataSource,
                report: this.dataReport,
                date: valueDate,
                from: this.dateFrom,
                to: this.dateTo,
                isFinal: this.isFinal,
            }

            parent.postMessage({ command: 'print-report', param }, '*')
            this.printReport(param)            
            setTimeout(() => {
                this.isLoadingButton = false
            }, 1500)
        },

        renderTrueBalance() {
            let today = this.renderDate(new Date())
            let trueBalance = this.dateFrom === this.dateTo && this.dateFrom === today ? this.dataSource.true_balance : this.dataSource.cash_difference
          
            return this.renderPrice(trueBalance)
        },

        renderDate(date) {
            const dateFormat = formatDate(new Date(date))

            let valueDate = `${dateFormat.year}-${dateFormat.month}-${dateFormat.day}`

            return valueDate
        },
        
        renderValueTitleDate(to, from) {
            return `${to} - ${from}`
        },
        renderPrice(price) {
            return formatPrice(price)
        },
    },
}
</script>
<style lang="scss">
@import '~ThemePath/scss/report/_index.scss';
</style>
