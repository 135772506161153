import BaseService from './base.service'
import SettingService from '@/services/setting.service'

const API_URL = JSON.parse(process.env.VUE_APP_SETTING_JSON).url
class HistoryService extends BaseService {
    async getLastNBet(limit) {
        return await this.get(API_URL + `/api/retail/report/last-n-bet/${limit}`).then(({ data }) => data)
    }
}

export default new HistoryService()
