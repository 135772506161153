<template>
  <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
  >
    <template v-slot:activator="{ on }">
      <button class="datepicker-button" v-on="on" :disabled="isLoading">
        <img v-if="!date" src="~ThemePath/images/results/calendar.png/" /><span
          v-if="!date"
      >{{ getTranslatedText(buttonText) }}</span
      >
        <img v-if="date" src="~ThemePath/images/results/calendar.png/" />
        {{ formatDate(date) }}
        <Loading v-if="isLoading" :isLoadingPage="false" />
      </button>
    </template>
    <v-date-picker
        v-model="date"
        no-title
        :weekday-format="getDay"
        :max="maxDate"
        :min="minDate"
        :locale="localeMonthDatePicker"
        :prev-icon="getTranslatedText('previous')"
        :next-icon="getTranslatedText('next')"
        class="custom-date-picker"
    >
      <template>
        <div class="datepicker__buttons">
          <button @click="menu = false" class="datepicker__buttons__cancel">
            {{getTranslatedText('cancel')}}
          </button>
          <button @click="onChangeDate()" class="datepicker__buttons__confirm">
            {{getTranslatedText('confirm')}}
          </button>
        </div>
      </template>
    </v-date-picker>
  </v-menu>

</template>


<script>
import languageMixins from "@/mixins/language/language-mixins";
import themeMixins from "@/mixins/pathAssets/path-assets-mixins";
import EventBus from "@/utils/EventBus";
import Loading from "@/components/common/loading/index.vue";

export default {
  mixins: [languageMixins, themeMixins],
  components: { Loading },
  props: {
    type: {
      type: String,
      default: "default",
    },
    keyValue: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
    maxDate: {
      type: String,
      default: "",
    },
    minDate: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      date: "",
      daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      localeMonthDatePicker: process.env.VUE_APP_I18N_LOCALE === 'fr' ? 'fr-FR' : 'en'
    }
  },
  created() {
    EventBus.$on("on-change-default-datepicker", (date) => (this.date = date));
  },
  destroyed() {
    EventBus.$off("on-change-default-datepicker");
  },
  methods: {
    getDay(date){
      if(process.env.VUE_APP_I18N_LOCALE === 'fr') {
        this.daysOfWeek = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];
      } else {
        this.daysOfWeek = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
      }
      let i = new Date(date).getDay(this.date)
      return this.daysOfWeek[i]
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    onChangeDate() {
      let param = {
        keyValue: this.keyValue,
        value: this.date,
      };
      EventBus.$emit("select-date-picker", param);
      this.menu = false;
    },
  },
};
</script>

<style lang="scss">
@import "~ThemePath/scss/common/_datepicker.scss";
</style>
