<template>
    <v-main>
        <LoadingScreen v-if="isLoadingScreen" />
        <ResultComponent
            v-else
            :dataSource="dataSource"
            :dataApi="dataApi"
            :date="date"
            :current="current"
            :currentTottal="currentTottal"
            :isLoadingSmall="LoadingScreenSmall"
            @typeGame="changeTypeGame"
            @changeDateFilter="changDateFilter"
        />
    </v-main>
</template>
<script>
import ResultService from '@/services/result.service'
import LoadingScreen from '@/components/common/loading/index.vue'
import ResultComponent from '@/components/Result/index.vue'
import EventBus from '@/utils/EventBus'
import { formatDate, isArrayEmpty, isStrEmpty } from '@/utils/utils'
import gamesService from '@/services/games.service'

export default {
    components: { LoadingScreen, ResultComponent },
    data() {
        return {
            dataSource: null,
            dataApi: null,
            date: '',
            isLoadingScreen: true,
            currentTottal: 0,
            current: 1,
            typeGame:'multi-lotto',
            slugGame: 'guinee lotto 5/90',
            LoadingScreenSmall:false
        }
    },

    created() {
        this.fetchData()
        EventBus.$on('select-date-picker', (param) => {
            const paramLoading = {
                isLoadingScreen: true,
                isLoadingButton: true,
            }
            EventBus.$emit('on-change-isloading-result', paramLoading)

            this.onChangeDate(param)
        })
        EventBus.$on('on-clear-searh-result', () => {
            const paramLoading = {
                isLoadingScreen: true,
                isLoadingButton: true,
            }
            EventBus.$emit('on-change-isloading-result', paramLoading)
            EventBus.$emit('on-change-default-datepicker', '')

            this.onChangeDate('')
        })
        EventBus.$on('on-pagination-result', (type) => this.onPagination(type))
    },
    destroyed() {
        EventBus.$off('select-date-picker')
        EventBus.$off('select-date-picker')
        EventBus.$off('on-clear-searh-result')
    },
    methods: {
        changeTypeGame(value){
            if(value === 'guinee lotto 5/90') {
              this.typeGame = 'multi-lotto'
            } else {
              this.typeGame = 'pari-mutuel-lotto'
            }
            this.slugGame = value
            this.fetchData()
            this.LoadingScreenSmall = true
        },
        changDateFilter(value) {
            let date =  ''
            if(!isStrEmpty(value)) {
                const [day, month, year] = value.split("/");
                date = `${year}-${month}-${day}`
            }
            this.fetchData(date);
        },
        onChangeDate(param) {
            this.fetchData(param.value)
        },
        async fetchData(date) {
            try {
                let dataFilter = !isStrEmpty(date) ? date : ''
                const paramLoading = {
                    isLoadingScreen: false,
                    isLoadingButton: false,
                }
                const resp = await ResultService.getList(dataFilter, this.typeGame)
                if (resp.code === 200) {
                    let dataResult = resp.data

                    if(this.typeGame === 'pari-mutuel-lotto') {
                        let type = ''
                        if (this.slugGame === "guinee lotto 6/45") type = 'lotto 6 of 45' 
                        if (this.slugGame === "loto 4/59") type = 'Loto 4/59'
                        if (this.slugGame === "loto 5/45") type = 'lotto 5 of 45'
                        dataResult = resp.data.filter((x) => x.game.toLowerCase() === type.toLowerCase())
                    }

                    let draw645 = localStorage.getItem('draw-645')
                    if(isStrEmpty(draw645)) {
                        const respDraw645 = await gamesService.getListEvents('pari-mutuel-lotto')
                        if(respDraw645.code === 200 && !isArrayEmpty(respDraw645.data)) {
                            localStorage.setItem('draw-645', respDraw645.data[0].draw_date)
                        }
                    }
                    this.dataApi = dataResult
                    this.currentTottal = dataResult.length / 10
                    this.current = 1
                    this.dataSource = dataResult.slice(0, 10)
                    this.date = this.renderDate()
                    this.isLoadingScreen = false
                    this.date = dataFilter
                    this.LoadingScreenSmall = false

                    EventBus.$emit('on-change-isloading-result', paramLoading)
                } else {
                    this.isLoadingScreen = false
                    this.LoadingScreenSmall = false
                    const param = {
                        text: resp.message,
                        type: 'error',
                    }
                    EventBus.$emit('show-notications', param)
                    EventBus.$emit('on-change-isloading-result', paramLoading)
                }
            } catch (error) {
                console.log(error)
                this.isLoadingScreen = false
                this.LoadingScreenSmall = false
            }
        },

        onPagination(type) {
            let dataApi = [...this.dataApi]
            let current = this.current
            let todosPerPage = 10

            current = type === 1 ? --current : ++current

            this.dataSource = dataApi.slice((current - 1) * todosPerPage, (current - 1) * todosPerPage + todosPerPage)
            this.current = current
            const paramLoading = {
                isLoadingScreen: false,
                isLoadingButton: false,
            }
            EventBus.$emit('on-change-isloading-result', paramLoading)
        },

        renderDate(date) {
            const dateFormat = formatDate(new Date(date))

            let valueDate = `${dateFormat.year}-${dateFormat.month}-${dateFormat.day}`

            return valueDate
        },
    },
}
</script>
