// import axios from 'axios';
import axiosAuth from "@/services/axios";
import axiosAuth2 from "@/services/axiosAuth2";

class BaseService {
    get<T = unknown>(url: string, data?: unknown) {
        return axiosAuth.get(url, {
            data,
        });
    }

    post<T = unknown>(url: string, data: unknown) {
        return axiosAuth.post(url, data);
    }

    put<T = unknown>(url: string, data: unknown) {
        return axiosAuth.put(url, data);
    }

    delete<T = unknown>(url: string) {
        return axiosAuth.delete(url);
    }

    getAuth2<T = unknown>(url: string, data?: unknown) {
        return axiosAuth2.get(url, {
            data,
        });
    }

    postAuth2<T = unknown>(url: string, data: unknown) {
        return axiosAuth2.post(url, data);
    }

    putAuth2<T = unknown>(url: string, data: unknown) {
        return axiosAuth2.put(url, data);
    }

    deleteAuth2<T = unknown>(url: string) {
        return axiosAuth2.delete(url);
    }
}

export default BaseService;
