<template>
    <div class="history">
        <h2 class="history__title">{{ getTranslatedText('history') }}</h2>
        <p class="count">{{ getTranslatedText(`Last ${countTicket} ticket`) }}</p>
        <HistoryTransaction :limit="countTicket"/>
    </div>
</template>
<script>
import languageMixins from '@/mixins/language/language-mixins'
import ButtonCommon from '@/components/common/button/index.vue'
import HistoryTransaction from '@/components/historyTransaction/index.vue'
import { getSettingSite, isStrEmpty } from '@/utils/utils'

export default {
    mixins: [languageMixins],
    components: { ButtonCommon, HistoryTransaction },
    data() {
        return {
            countTicket: 5,
            isLoadingButton: false,
        }
    },

    created() {
        this.getDesc()
    },

    methods: {
        getDesc() {
            let setting = getSettingSite()
            if (!isStrEmpty(setting)) {
                setting = JSON.parse(setting)
                if (setting && parseInt(setting.history_limit) > 0) {
                    this.countTicket = parseInt(setting.history_limit)
                }
            }
        },
    },
}
</script>
<style lang="scss">
@import '~ThemePath/scss/history/_index.scss';
</style>
