<template>
    <div class="notications">
        <v-alert v-if="showAlert" :type="alertType" dismissible @input="showAlert = false">
            <div class="notications__title">{{ getTranslatedText(alertType) }}</div>
            <span class="up-first" v-if="!isNotTranslated"> {{ getTranslatedText(alertText) }} </span>
            <span class="up-first" v-else> {{ alertText }} </span>
        </v-alert>
    </div>
</template>

<script>
import languageMixins from '@/mixins/language/language-mixins'
import EventBus from '@/utils/EventBus'
import { isStrEmpty } from '@/utils/utils'

export default {
    mixins: [languageMixins],
    data() {
        return {
            showAlert: false,
            alertText: '',
            alertType: '',
            className: '',
            isNotTranslated: false,
            timeoutDuration: 3000,
        }
    },
    created() {
        EventBus.$on('show-notications', (param) => this.showAlertWithTimeout(param))
    },
    destroyed() {
        EventBus.$off('show-notications')
    },
    methods: {
        showAlertWithTimeout(param) {
            this.alertText = param.text
            this.alertType = param.type
            this.isNotTranslated = param?.isNotTranslated
            this.className = !isStrEmpty(param.className) ? param.className : ''

            this.showAlert = true
            setTimeout(() => {
                this.showAlert = false
            }, this.timeoutDuration)
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/common/_notications.scss';
</style>
