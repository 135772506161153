<template>
    <div>
        <LoadingScreen v-if="isLoadingScreen"></LoadingScreen>
        <div class="loto__list__container" v-else>
            <div class="loto__list__head__container">
                <div class="loto__list__logo">
                    <img src="~ThemePath/images/logo.png" />
                </div>
                <div class="loto__list__head">
                    <div class="loto__list__head__name">
                        {{ getTranslatedText(`guinne ${ dataDetail.title }`) }}
                    </div>
                    <div class="loto__list__head__title">{{ dataDetail.title }} {{ dataDetail.gameNumber }}</div>
                    <div class="loto__list__head__sub__title">
                        {{ getTranslatedText('Select Event') }}
                    </div>
                    <div class="loto__list__head__button">
                        <button @click="onSelectAll">{{ getTranslatedText('Select All') }}</button>
                    </div>
                </div>
            </div>
            <div class="loto__list__items">
                <div class="loto__list__item g590" :class="{ active: eventSelect.includes(v.event_id) }" v-for="(v, i) in dataEvents" :key="i" @click="onSelectEvent(v.event_id, v)">
                    <div class="loto__list__item__name">
                        {{ v.draw_name }}
                    </div>
                    <div class="loto__list__item__time">
                        {{ renderDate(v.draw_date) }}
                    </div>
                </div>
                <div class="loto__list__buttons">
                    <div class="loto__list__button__back" @click="handleBack()">
                        <button>{{ getTranslatedText('Back') }}</button>
                    </div>
                    <div class="loto__list__button__continue" @click="handleContinue">
                        <button>{{ getTranslatedText('Continue') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LoadingScreen from '@/components/common/loading/index.vue'
import languageMixins from '@/mixins/language/language-mixins'
import GamesService from '@/services/games.service'
import EventBus from '@/utils/EventBus'
import ButtonCommon from '@/components/common/button/index.vue'
import { formatPrice, isSameDay, isArrayEmpty, formatDateUTC00, isStrEmpty } from '@/utils/utils'
const WAGER_MIN_ENV = process.env.VUE_APP_WAGER_MIN || 2000

export default {
    mixins: [languageMixins],
    components: { ButtonCommon, LoadingScreen },
    data() {
        return {
            dataDetail: {},
            eventSelect: [],
            eventInfo: [],
            isLoadingScreen: true,
        }
    },
    created() {
        let dataDetail = localStorage.getItem('game-loto')
        if (isStrEmpty(dataDetail)) {
            this.$router.push('/loto')
        }
        this.dataDetail = JSON.parse(dataDetail)
        this.fetchDataDetail()
    },
    destroyed() {},
    methods: {
        async fetchDataDetail() {
            try {
                const resp = await GamesService.getListEvents('multi-lotto')
                if (resp.code === 200) {
                    this.dataEvents = resp.data
                    this.$store.dataListEvents = resp.data
                    this.isLoadingScreen = false
                }
            } catch (error) {
                this.isLoadingScreen = false
                console.log(error)
            }
        },

        handleContinue() {
            if (isArrayEmpty(this.eventSelect)) {
                const param = {
                    text: 'Please choose the game',
                    type: 'warning',
                }
                EventBus.$emit('show-notications', param)

                return false
            }
            let dataNumber = {
                game: this.dataDetail.name,
                title: this.dataDetail.title,
                gameNumber: this.dataDetail.gameNumber,
                event_id: this.eventSelect,
                numberQp: this.dataDetail.numberQp,
                eventInfo: this.eventInfo,
                selection: [],
                draws: 1,
                wager: this.dataDetail.price,
                isPariMutuelLotto: false,
                priceNumber: this.renderPrice(WAGER_MIN_ENV, 0),
                defaultWager: this.renderPrice(WAGER_MIN_ENV, 0),
                allowedPicks: this.dataDetail.allowedPicks,
            }
            localStorage.setItem('loto-number', JSON.stringify(dataNumber))
            this.$router.push('/loto-number')
        },

        onSelectAll() {
            if (this.dataEvents.length === this.eventSelect.length) {
                this.eventSelect = []
                this.eventInfo = []
            } else {
                this.dataEvents.forEach((item) => {
                    const index = this.eventSelect.findIndex((x) => x === item.event_id)
                    if (index === -1) {
                        this.eventSelect.push(item.event_id)
                        this.eventInfo.push({
                            name: item.draw_name,
                            date: item.draw_date,
                        })
                    }
                })
            }
        },

        onSelectEvent(eventId, item) {
            const index = this.eventSelect.findIndex((x) => x === eventId)
            if (index !== -1) {
                this.eventSelect.splice(index, 1)
                this.eventInfo.splice(index, 1)
            } else {
                this.eventSelect.push(eventId)
                this.eventInfo.push({
                    name: item.draw_name,
                    date: item.draw_date,
                })
            }
        },

        handleBack() {
            this.$router.push('/loto')
        },

        renderDate(date) {
            const dateFormat = formatDateUTC00(date)
            const isCheckSameDay = isSameDay(date)
            return isCheckSameDay ? `${dateFormat.hour}H${dateFormat.minutes}` : `${dateFormat.day}/${dateFormat.month}/${dateFormat.year} ${dateFormat.hour}H${dateFormat.minutes}`
        },
        renderPrice(price, decimal) {
            return formatPrice(price, decimal)
        },
    },
}
</script>
<style lang="scss">
@import '~ThemePath/scss/loto/_index.scss';
</style>
