<template>
    <v-main>
        <LoadingScreen v-if="isLoadingScreen" />
        <ReportComponent v-else :dataSource="dataSource" :cashier="cashier" :dateTo="dateTo" :dateFrom="dateFrom" :dataReport="dataReport" :isFinal="this.$route.meta.isFinal" :valueDate="valueDate" />
    </v-main>
</template>
<script>
import languageMixins from '@/mixins/language/language-mixins'
import LoadingScreen from '@/components/common/loading/index.vue'
import ReportComponent from '@/components/Report/index.vue'
import ReportService from '@/services/report.service'
import EventBus from '@/utils/EventBus'
import { formatDateUTC00 } from '@/utils/utils'

export default {
    mixins: [languageMixins],
    components: { LoadingScreen, ReportComponent },
    data() {
        return {
            dataSource: null,
            cashier: null,
            dataReport: [],
            isLoadingScreen: true,
            dateFrom: new Date(),
            dateTo: new Date(),
            valueDate: '',
        }
    },
    created() {
        this.fetchData()
        EventBus.$on('select-date-picker', (param) => {
            this.onChangeDatePicker(param)
        })
    },
    destroyed() {
        EventBus.$off('select-date-picker')
    },
    methods: {
        onChangeDatePicker(param) {
            if (param.keyValue === 'from') this.dateFrom = param.value
            else this.dateTo = param.value
            this.fetchData()
        },
        async fetchData() {
            try {
                let dateFrom = this.renderDate(this.dateFrom)
                let dateTo = this.renderDate(this.dateTo)
                let userInfo = this.$store.state.userInfo
                const param = {
                    from: dateFrom,
                    to: dateTo,
                }

                const res = await ReportService.report(param)

                if (res.status === 200) {
                    let param = {
                        currency: '',
                        cash_deposits: 0,
                        daily_profit: 0,
                        float_adjustments: 0,
                        cash_difference: 0,
                        true_balance: res.data.true_balance,
                        refunded_cancelations: 0,
                        payouts: 0,
                    }

                    res.data.reports.map((r) => {
                        param.currency = r.currency
                        param.cash_deposits += parseFloat(r.cash_deposits)
                        param.daily_profit += parseFloat(r.daily_profit)
                        param.float_adjustments += parseFloat(r.float_adjustments)
                        param.cash_difference += parseFloat(r.cash_difference)
                        param.refunded_cancelations += parseFloat(r.refunded_cancelations || 0)
                        param.payouts += parseFloat(r.payouts)

                        r['product_type'] = this.getTranslatedText(r.product_type)
                    })
                    let valueDate = dateFrom == dateTo ? this.renderDate(this.dateTo, true) : `${this.renderDate(this.dateFrom, true)} - ${this.renderDate(this.dateTo, true)}`

                    let cashier = userInfo?.details
                    cashier['locked'] = res.data.cashier_locked_status
                    cashier['title'] = 'Report of'
                    this.valueDate = valueDate
                    this.dataSource = param
                    this.cashier = cashier
                    this.dataReport = res.data.reports
                    this.isLoadingScreen = false
                    EventBus.$emit('change-is-loading-report')
                } else {
                    this.dataSource = null
                    this.dataReport = []
                    this.isLoadingScreen = false

                    EventBus.$emit('change-is-loading-report', true)
                    const param = {
                        text: res.message,
                        type: 'error',
                    }
                    EventBus.$emit('show-notications', param)
                }
                this.dateFrom = dateFrom
                this.dateTo = dateTo
            } catch (error) {
                this.isLoadingScreen = false
                console.log(error)
            }
        },

        renderDate(date, isRenderValue = false) {
            const dateFormat = formatDateUTC00(date)

            let valueDate = isRenderValue ? `${dateFormat.day}/${dateFormat.month}/${dateFormat.year}` : `${dateFormat.year}-${dateFormat.month}-${dateFormat.day}`

            return valueDate
        },
    },
} 
</script>
