<template>
    <div class="scan-ticket-detail__list-table">
        <div class="scan-ticket-detail__table">
            <span class="scan-ticket-detail__table-title">{{ getTranslatedText('ticket') }}</span>
            <div id="lottoIframe"></div>
        </div>
    </div>
</template>
<script>
import languageMixins from '@/mixins/language/language-mixins'

export default {
    mixins: [languageMixins],
    props: {
        dataSource: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            iframe: null,
        }
    },
    mounted() {
        const scriptJquery = document.createElement('script')
        scriptJquery.src = 'https://code.jquery.com/jquery-3.6.1.slim.min.js'
        scriptJquery.integrity = 'sha256-w8CvhFs7iHNVUtnSP0YKEg00p9Ih13rlL9zGqvLdePA='
        scriptJquery.crossOrigin = 'anonymous'
        scriptJquery.addEventListener('load', () => {
            const scriptSport = document.createElement('script')
            scriptSport.type = 'text/javascript'
            scriptSport.src = `${this.dataSource.sport_url}/theme/base/js/iframe.js`
            scriptSport.addEventListener('load', () => {
                this.iframe = new iframeParent(
                    'lottoIframe',
                    `${this.dataSource.sport_url}/accounts/transactions`,
                    {
                        code: this.dataSource.sport_code,
                    },
                    {
                        token: this.dataSource.sport_token,
                    }
                )
                this.iframe.init()

                this.iframe.requestHistory({
                    code: this.dataSource.sport_code,
                    page: 1,
                    dateFrom: '',
                    dateTo: '',
                    betId: this.dataSource.sport_bet_id,
                    gameStatus: '',
                    locale: '',
                    token: this.dataSource.sport_token,
                })
            })
            document.head.appendChild(scriptSport)
        })

        document.head.appendChild(scriptJquery)
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/scan-ticket/_detail.scss';
</style>
