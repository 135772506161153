import store from '@/store/index'
import BaseService from './base.service'

const API_URL = process.env.VUE_APP_API_URL
interface multiLottoInterface {
    account: string
    currency: string
}

interface listEventsInterface {
    account: string
}

class GamesService extends BaseService {
    getListGames(typeGame: string) {
        const payload: multiLottoInterface = {
            account: store.state.userInfo.gamev3.account,
            currency: store.state.userInfo.gamev3.currency_code,
        }
        return this.postAuth2(`${API_URL}/api/${typeGame ? typeGame : 'multi-lotto'}/games`, payload).then(({ data }) => data)
    }
    getListEvents(typeGame: string) {
        const payload: listEventsInterface = {
            account: store.state.userInfo.gamev3.account,
        }

        return this.postAuth2(`${API_URL}/api/${typeGame ? typeGame : 'multi-lotto'}/draws`, payload).then(({ data }) => data)
    }
}

export default new GamesService()
