<template>
    <div class="login">
        <img src="~ThemePath/images/logo2.png/" />
        <p>{{ handleTextAppVersion() }}</p>
        <h2 class="login__title">{{ getTranslatedText('login terminal') }}</h2>
        <FormCommon
            :formItem="formItem"
            :dataForm="dataForm"
            nameFunction="on-change-value"
        />
        <div class="login__box-button">
            <ButtonCommon
                className="w100"
                :isNoHover="true"
                :isLoading="isLoadingButton"
                value="login"
                nameFunction="on-click-login"
            />
        </div>
    </div>
</template>
<script>
import themeMixins from '@/mixins/pathAssets/path-assets-mixins'
import languageMixins from '@/mixins/language/language-mixins'
import FormCommon from '@/components/common/form/index.vue'
import ButtonCommon from '@/components/common/button/index.vue'
import EventBus from '@/utils/EventBus'
import AuthService from '@/services/auth.service'
import {isArrayEmpty, isStrEmpty, parseSetting} from '@/utils/utils'
import { version } from '../../../package.json'
import {setToken, setTokenV3, setUserInfo} from '@/services/axios'
import store from "@/store";
const ACCOUNT = process.env.VUE_APP_ACCOUNT
const IS_DEBUG = process.env.VUE_APP_IS_DEBUG

export default {
    mixins: [themeMixins, languageMixins],
    components: { FormCommon, ButtonCommon },
    data() {
        return {
            isLoadingButton: false,
            appVersion: version,
            ACCOUNT: ACCOUNT,
            IS_DEBUG: IS_DEBUG,
            formItem: [
                {
                    label: 'terminal',
                    name: 'terminal',
                    type: 'text',
                    required: true,
                },
                {
                    label: 'terminal pin',
                    name: 'terminalPin',
                    type: 'password',
                    required: true,
                },
            ],
            dataForm: {
                terminal: '',
                terminalPin: '',
            },
        }
    },
    created() {
        EventBus.$on('on-change-value', (value) => {
            this.changeValueLogin(value)
        })
        EventBus.$on('on-click-login', () => {
            this.handleLogin()
        })
    },

    destroyed() {
        EventBus.$off('on-change-value')
        EventBus.$off('on-click-login')
    },

    methods: {
        handleTextAppVersion() {
            return this.$t(`version ${this.appVersion}`)
        },
        changeValueLogin(item) {
            let { event, name } = item
            this.dataForm[name] = event.target.value
        },

        async handleLogin() {
            try {
                this.isLoadingButton = true
                const param = {
                    terminal_id: this.dataForm.terminal,
                    terminal_pin: this.dataForm.terminalPin,
                    login_method: 'terminal',
                }
                // reset warining of location permission
                localStorage.removeItem('location_permission_waring_count')
                const res = await AuthService.login(param)
                if (res.status === '200') {
                    console.log(res)
                      this.$store.state.tokenV3 = res.data.gamev3.token
                      setTokenV3(res.data.gamev3.token)
                      setToken(res.data.token)
                      const paramInfo = { ...res.data }
                      paramInfo.config = parseSetting(AuthService.readSettingFromEnv())
                      delete paramInfo.token
                      setUserInfo(paramInfo)
                      await this.$store.dispatch('handleUserInfo', paramInfo)
                      this.$router.go('/')
                  } else {
                      let message = ''
                      !isArrayEmpty(res.errors)
                          ? res.errors.forEach((element) => {
                                message += element.message
                            })
                          : (message = res.message)
                      const param = {
                          text: message,
                          type: 'error',
                      }
                      EventBus.$emit('show-notications', param)
                  }
                this.$store.state.isShow645 = res.data?.details?.enabled_game_slugs?.indexOf('lotto-6-45') !== -1;
                this.$store.state.isShowSports = res.data?.details?.enabled_game_slugs?.indexOf('bitville-sports') !== -1;
                this.$store.state.reprintCountToShowCancel = res.data?.details?.reprint_count_to_show_cancel ? parseInt(res.data?.details?.reprint_count_to_show_cancel) : null;
                this.$store.state.milisecondsToForceFinishPrinting = res.data?.details?.miliseconds_to_force_finish_printing ? parseInt(res.data?.details?.miliseconds_to_force_finish_printing) : null;
                this.$store.state.networkTimeoutSeconds = res.data?.details?.network_timeout_seconds ? parseInt(res.data?.details?.network_timeout_seconds) : null;
                // todo: storing store here make no sense since state is reseted after move to home
                this.isLoadingButton = false
            } catch (error) {
                this.isLoadingButton = false
                const param = {
                  text: (error?.message ?? error),
                  type: 'error',
                }
                
                if(!error.response && isStrEmpty(error?.message)) {
                    param['text'] = this.getTranslatedText('Request timeout. Please check your network connection.')
                }

                EventBus.$emit('show-notications', param)
            }
        },
    },
}
</script>
<style lang="scss">
@import '~ThemePath/scss/login/_index.scss';
</style>
