<template>
    <v-main>
        <LoadingScreen v-if="isLoadingScreen" />
        <div class="sport" v-else>
            <div class="sport__header">
                <div class="sport_home" @click="$router.push('/')">
                    <img src="~ThemePath/images/chevron-left-green.png" />
                    <span class="sport_text">{{ getTranslatedText('home') }}</span>
                </div>
                <span class="sport_text sports">{{ getTranslatedText('sports') }}</span>
            </div>
            <div class="sport__iframe">
                <iframe :src="iframeUrl"></iframe>
            </div>
        </div>
        <ReprintDialog />
    </v-main>
</template>

<script>
import EventBus from '@/utils/EventBus'
import LoadingScreen from '@/components/common/loading/index.vue'
import languageMixins from '@/mixins/language/language-mixins'
import printMixin from '@/mixins/print/print-mixin'
import { getAccessToken } from '@/services/axios'
import { getSettingDevice, isStrEmpty } from '@/utils/utils'
import ReprintDialog from '@/components/common/dialog/reprint.vue'

const SETTING_JSON = process.env.VUE_APP_SETTING_JSON

export default {
    mixins: [languageMixins, printMixin],
    components: { LoadingScreen, ReprintDialog },
    data() {
        return {
            isLoadingScreen: false,
            iframeUrl: '',
        }
    },
    mounted() {
        this.renderIframe()
        window.addEventListener('message', this.handleIframeMessage)
    },
    beforeDestroy() {
        window.removeEventListener('message', this.handleIframeMessage)
    },
    methods: {
        handleIframeMessage(event) {
            if (event.data.type === 'cashout' && event.data.action === 'print') {
                let userInfo = this.$store.state.userInfo
                parent.postMessage({ command: 'print-sports', param: event.data.data, userInfo }, '*')
                this.printSports(event.data.data)
                this.showReprintDialog(JSON.parse(event.data.data)[0].transactions[0].id, event.data.data)
            }
        },

        showReprintDialog(reference, paramPrint) {
            EventBus.$emit('on-show-reprint', {
                ticketType: 'S',
                reference: reference,
                betslipId: reference.startsWith('S-') ? reference : 'S-' + reference,
                paramPrint: paramPrint,
                forSport: true
            })
        },

        renderIframe() {
            let setting = getSettingDevice()
            // if (isStrEmpty(setting)) {
            //     setting = JSON.stringify({ mac: 'fake_mac', appVersion: 'fake_version', serial: 'fake_serial', uniqueId: 'fake_uuid' })
            // }

            if (!isStrEmpty(setting)) {
                const SETTING_ENV = JSON.parse(SETTING_JSON)
                setting = JSON.parse(setting)
                const token = getAccessToken()
                if (!setting.position) setting.position = {}
                setting.position.error_message = setting.location_error
                const p = JSON.stringify(setting.position)
                this.iframeUrl = `${SETTING_ENV.url}/retail/sport-page-for-pos/index?locale=fr&admin_jwt=${token}
                &t=${Date.now()}&device_network_mac_address=${setting.mac}
                &app_version=${setting.appVersion}&device_serial_number=${setting.serial}
                &device_unique_id=${setting.uniqueId}&toggleTournaments=false
                &position=${p}`
            }
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/sport/_index.scss';
</style>
