<template>
    <section>
        <PrintComponent />
    </section>
</template>
<script>
import PrintComponent from '@/components/Print/index.vue'

export default {
    components: { PrintComponent },
}
</script>
<style lang="scss">
@import '~ThemePath/scss/print/_index.scss';
</style>
