<template>
    <div>
        <LoadingScreen v-if="isLoadingScreen"></LoadingScreen>
        <div v-else class="loto__container">
            <div @click="$router.push('/')" class="loto__back">
                <img src="~ThemePath/images/chevron-left-green.png" />
                <div class="loto__back__title">
                    {{ getTranslatedText('Home') }}
                </div>
            </div>
            <div class="loto__logo">
                <img src="~ThemePath/images/logo.png" />
            </div>
            <div class="loto__items__container">
                <div class="loto__items__item" v-for="(v, i) in dataSource" :key="i">
                    <div class="loto__items__item__title">
                        {{ getTranslatedText(`loto ${v.title}`) }}
                    </div>
                    <div class="loto__box__container">
                        <div class="loto__item__box g590" v-for="(value, index) in v.listRanDomNumber" :key="index"
                            @click="onClickGames(v, value)">
                            <span>
                                {{ getTranslatedText(`${value.title}`) }} <br />
                                <span v-show="value.isShowNumber">{{ getTranslatedText(`${value.number}`) }}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LoadingScreen from '@/components/common/loading/index.vue'
import languageMixins from '@/mixins/language/language-mixins'
import GamesService from '@/services/games.service'

export default {
    mixins: [languageMixins],
    components: { LoadingScreen },
    data() {
        return {
            dataSource: [],
            isLoadingScreen: true,
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        async fetchData() {
            try {
                const resp = await GamesService.getListGames('multi-lotto')
                if (resp.code === 200) {
                    let listGames = resp.data.reduce(function (r, a) {
                        let name = a.game.config.grouping
                        r[name] = r[name] || []
                        r[name].push(a)
                        return r
                    }, Object.create(null))
                    let maxiumBallsSorted = 5
                    let minimumBallsSorted = 0
                    //const dataSourceOrder = ['turbo', 'nap', 'chance', 'extra']
                    const dataSourceOrder = [...new Set(resp.data.map(item => item.game.config.grouping))]
                    let dataSource = []
                    Object.keys(listGames).map((x) => {
                        let itemGame = listGames[x]
                        if (!dataSourceOrder.includes(itemGame[0].game.config.grouping)) {
                            return false
                        }
                        minimumBallsSorted = maxiumBallsSorted - listGames[x].length + 1
                        let name = itemGame[0].game.slug.substr(0, itemGame[0].game.slug.length - 1)
                        let allowedPicks = itemGame[0].game.config.allowed_picks
                        let listRanDomNumber = Array.from(Array(maxiumBallsSorted - minimumBallsSorted + 1), (_, i) => {
                            return {
                                number: allowedPicks ? itemGame[i].game.name.slice(-1) : minimumBallsSorted + i,
                                title: allowedPicks ? `${x} ${itemGame[i].game.name.slice(-1)}` : x,
                                isShowNumber: !allowedPicks,
                                active: false
                            }
                        })

                        let item = {
                            id: Math.random().toString(16).slice(2),
                            title: x,
                            listRanDomNumber: listRanDomNumber.sort((a, b) => a.title.localeCompare(b.title)),
                            name,
                            price: itemGame[0].game.price,
                            allowedPicks: allowedPicks
                        }
                        dataSource.push(item)
                    })
                    dataSource = dataSource.sort((a, b) => {
                        return dataSourceOrder.indexOf(a.title) - dataSourceOrder.indexOf(b.title)
                    })
                    this.dataSource = dataSource
                    this.loadingPage = false
                    this.isLoadingScreen = false
                }
            } catch (err) {
                this.loadingPage = false
                this.isLoadingScreen = false
                console.log(err, 'err')
            }
        },
        onClickGames(games, itemGame) {
            let dataDetail = {
                title: games.title,
                gameNumber: itemGame.number,
                name: games.name,
                price: games.price,
                numberQp: games.title === 'turbo' ? 2 : itemGame.number,
                allowedPicks: games.allowedPicks,
                wager: this.WAGER_MIN_ENV,
            }
            dataDetail = JSON.stringify(dataDetail)
            localStorage.setItem('game-loto', dataDetail)
            this.$router.push('/loto-events')
        },
    },
}
</script>
<style lang="scss">
@import '~ThemePath/scss/loto/_index.scss';
</style>
