<template>
<div>
  <div class="navigation__wrap">
    <div class="navigation__container">
      <div v-if="this.$store.state.isShow645 || this.$store.state.isShow590" class="navigation__bestslip" @click="handleToggleBetslip()">
        <p>{{ getTranslatedText('Betslip') }} <span>{{ $store.state.quantity }}</span></p>
        <button v-if="!isOpenBetslip"><img src="~ThemePath/images/chevron.png" alt="" /></button>
        <button v-if="isOpenBetslip" class="navigation__bestslip__icon-down"><img src="~ThemePath/images/chevron.png" alt="" /></button>
      </div>
      <div class="navigation__content">
        <ul class="navigation__content--list">
          <li @click="navigateMenu('/')"><a><span><img src="~ThemePath/images/home.png" alt="home"></span><span>{{ getTranslatedText('home') }}</span></a></li>
          <li @click="navigateMenu('/scan-ticket')"><a><span><img src="~ThemePath/images/scan.png" alt="scan"></span><span>{{ getTranslatedText('scan') }}</span></a></li>
          <li @click="navigateMenu('/history')"><a><span><img src="~ThemePath/images/report.png" alt="scan"></span><span>{{ getTranslatedText('history') }}</span></a></li>
          <li @click="navigateMenu('/report')"><a><span><img src="~ThemePath/images/report.png" alt="report"></span><span>{{ getTranslatedText('report') }}</span></a></li>
          <template v-if="this.$store.state.isShow645 || this.$store.state.isShow590">
            <li @click="navigateMenu('/results')"><a><span><img src="~ThemePath/images/result.png" alt="result"></span><span>{{ getTranslatedText('result') }}</span></a></li>
            <!-- <li @click="navigateMenu('/about')"><a><span><img src="~ThemePath/images/about.png" alt="about"></span><span>{{ getTranslatedText('about') }}</span></a></li> -->
          </template>
            <li @click="onShowBetslip()"><a><span><img src="~ThemePath/images/cart.png" alt="result"></span><span>{{ getTranslatedText('Betslip') }}</span></a></li>
          <!-- <li @click="navigateMenu('/print')"><a><span><img src="~ThemePath/images/print.png" alt="print"></span><span>{{ getTranslatedText('print') }}</span></a></li> -->
        </ul>
      </div>
    </div>
  </div>
  <div v-if="isOpenBetslip">
    <Cart
        @close-cart="handleToggleBetslip"
        @continue-paying="handleContinuePaying"
    ></Cart>
  </div>
</div>
</template>
<style lang="scss">
@import "~ThemePath/scss/navigation/_index.scss";
</style>
<script>
import languageMixins from '@/mixins/language/language-mixins'
import Cart from '@/views/cart/index.vue'
import EventBus from '@/utils/EventBus'
export default {
  components:{
    Cart
  },
  mixins: [languageMixins],
  data() {
    return {
      isOpenBetslip: false,
      dataCart: {
        cart: [],
        quantity: 0,
        transact: [],
        totalCart: 0,
      }
    }
  },
  methods: {
    navigateMenu(url) {
      this.$router.push(url)
      this.isOpenBetslip = false
      parent.postMessage({ command: 'is-show-cammera', param: false }, '*')
      EventBus.$emit('on-hide-camere-scan')
    },
    handleToggleBetslip() {
      this.isOpenBetslip = !this.isOpenBetslip;
    },
    onShowBetslip() {
      this.isOpenBetslip = true
    },
    handleContinuePaying() {
      this.isOpenBetslip = false;
      this.$router.push('/')
    },
  }
}
</script>
