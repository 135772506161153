<template>
    <div class="voucher">
        <template v-if="!isShowScan">
            <div class="voucher-back" @click="$router.go(-1)">
                <img src="~ThemePath/images/chevron-left-green.png" />
                <span class="voucher-back-text">
                    {{ getTranslatedText('back') }}
                </span>
            </div>
            <div v-if="$store.state.isShowVoucher" class="voucher-container">
                <h1 class="voucher-title">{{ getTranslatedText('VOUCHERS') }}</h1>
                <div class="voucher__input">
                    <label>{{ getTranslatedText('Entrer le montant du voucher') }}</label>
                    <div class="voucher__input__content">
                        <input v-model="voucherAmount" type="number" pattern="[0-9]*" inputmode="numeric" />
                    </div>
                </div>
                <div class="voucher-list-amount">
                    <button class="voucher-list-amount-item" v-for="(v, i) in listNumberVoucherAmount" :key="i" @click="onChangeVoucherAmount(v)">{{ v }}</button>
                </div>
                <ButtonCommon type="error" className="w100" :isNoHover="true" :isLoading="isLoadingBtnCreate" :isDisable="voucherAmount === ''" value="buy" nameFunction="on-create-voucher-amount" />
            </div>
        </template>
        <div class="voucher-action voucher-container" :class="{ mrTop: !$store.state.isShowVoucher }">
            <h3 class="voucher-action-title">{{ getTranslatedText('Pour verifier ou payer un voucher') }}</h3>
            <div class="voucher__input" v-if="!isShowScan">
                <label>{{ getTranslatedText('Entrer le code du voucher') }}</label>
                <div class="voucher__input__content">
                    <input v-model="voucherCode" type="text" @focus="onFocusVoucherCode" />
                </div>
            </div>
            <ButtonCommon type="error" className="w100" :isNoHover="true" :isLoading="isLoadingCodeVoucher" :value="isShowScan ? 'close voucher' : 'SCANNER LE VOUCHER'" nameFunction="on-scan-voucher" />
        </div>
        <div v-if="!isShowScan" class="voucher-action-bottom voucher-container">
            <ButtonCommon className="w100" type="default2" :isNoHover="true" :isLoading="isLoadingCodeVoucher" :isDisable="voucherCode === ''" value="cancel" nameFunction="on-cancel-voucher" />
            <ButtonCommon className="w100" :isNoHover="true" :isLoading="isLoadingCodeVoucher" :isDisable="voucherCode === ''" value="payment" nameFunction="on-payment-voucher" />
        </div>
    </div>
</template>
<script>
import EventBus from '@/utils/EventBus'
import ButtonCommon from '@/components/common/button/index.vue'
import languageMixins from '@/mixins/language/language-mixins'
import printMixins from '@/mixins/print/print-mixin'
import voucherService from '@/services/voucher.service'

const CURRENCY = process.env.VUE_APP_CURRENCY
export default {
    mixins: [languageMixins, printMixins],
    components: { ButtonCommon },
    data() {
        return {
            listNumberVoucherAmount: [2000, 5000, 10000, 50000],
            voucherAmount: '',
            voucherCode: '',
            isLoadingCodeVoucher: false,
            isLoadingBtnCreate: false,
            isShowScan: false,
        }
    },
    mounted() {
        document.addEventListener('message', this.onDecode)
    },
    created() {
        EventBus.$on('on-create-voucher-amount', () => {
            this.onCreateVoucherAmount()
        })
        EventBus.$on('on-scan-voucher', () => {
            this.onScanVoucher()
        })
        EventBus.$on('on-cancel-voucher', () => {
            this.onCancelVoucher()
        })
        EventBus.$on('on-payment-voucher', () => {
            this.onPaymentVoucher()
        })
    },
    destroyed() {
        EventBus.$off('on-create-voucher-amount')
        EventBus.$off('on-scan-voucher')
        EventBus.$off('on-cancel-voucher')
        EventBus.$off('on-payment-voucher')
    },
    methods: {
        showNotification(text, type, isNotTranslated = false) {
            const param = {
                text: text,
                type: type,
                isNotTranslated,
            }
            EventBus.$emit('show-notications', param)
        },

        async onCreateVoucherAmount() {
            try {
                this.isLoadingBtnCreate = true

                const resp = await voucherService.createVoucher(this.voucherAmount)
                if (resp.status === 200) {
                    this.voucherAmount = ''
                    resp.data['currency'] = CURRENCY
                    this.showNotification(resp.message, 'success', true)
                    parent.postMessage({ command: 'print-create-voucher', param: resp.data }, '*')
                    this.printVoucher(resp.data)
                } else {
                    this.showNotification(resp.message, 'error')
                }

                this.isLoadingBtnCreate = false
            } catch (error) {
                console.log(error)
            }
        },

        async validateVoucher() {
            try {
                let voucherCode = this.voucherCode
                const response = await voucherService.checkVoucher(voucherCode)
                const param = {
                    response,
                    voucherCode,
                }
                return param
            } catch (error) {
                console.log(error)
            }
        },

        async onCancelVoucher() {
            try {
                this.isLoadingCodeVoucher = true

                const resp = await voucherService.cancelVoucher(this.voucherCode)

                if (resp.status === 200) {
                    let dataPrint = {
                        amount: resp.data,
                        currency: CURRENCY,
                        voucherCode: this.voucherCode,
                        isCancel: true,
                    }

                    this.showNotification('cancel voucher success', 'success')
                    this.voucherCode = ''
                    parent.postMessage({ command: 'print-create-voucher', param: dataPrint }, '*')
                    this.printVoucher(dataPrint)
                } else {
                    this.showNotification(resp.message, 'error')
                }
                this.isLoadingCodeVoucher = false
            } catch (error) {
                console.log(error)
            }
        },

        async onPaymentVoucher() {
            try {
                this.isLoadingCodeVoucher = true

                const resp = await voucherService.payVoucher(this.voucherCode)

                if (resp.status === 200) {
                    this.showNotification('payment voucher success', 'success')
                    this.voucherCode = ''
                    const { code, currency_code, winnings } = resp.data

                    let dataPrint = {
                        isPayment: true,
                        voucherCode: code,
                        amount: winnings,
                        currency: currency_code,
                        code128: true,
                    }
                    parent.postMessage({ command: 'print-create-voucher', param: dataPrint }, '*')
                    this.printVoucher(dataPrint)
                } else {
                    this.showNotification(resp.message, 'error')
                }
                this.isLoadingCodeVoucher = false
            } catch (error) {
                console.log(error)
            }
        },

        onChangeVoucherAmount(number) {
            this.voucherAmount = number
        },

        onScanVoucher() {
            this.isShowScan = !this.isShowScan
            parent.postMessage({ command: 'is-show-cammera', param: this.isShowScan }, '*')
        },

        onDecode(event) {
            const resp = JSON.parse(event.data)
            if (resp.command === 'on-decode-scan') {
                let value = resp.data
                if (value.charAt(0) === value.charAt(value.length - 1)) {
                    value = value.slice(1, -1)
                }
                this.voucherCode = value
                this.isShowScan = false
                parent.postMessage({ command: 'is-show-cammera', param: this.isShowScan }, '*')
            }
        },

        onFocusVoucherCode() {
            setTimeout(() => {
                window.scrollTo(0, 500)
            }, 300)
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/voucher/_index.scss';
</style>
