import BaseService from "./base.service"
import store from '@/store/index'

const API_URL = process.env.VUE_APP_API_URL
interface multiLottoInterface {
    account: string
    currency: string
}


class PromotionsService extends BaseService {
    getActivePromotions() {
        const payload: multiLottoInterface = {
            account: store.state.userInfo.gamev3.account,
            currency: store.state.userInfo.gamev3.currency_code,
        }
        return this.postAuth2(`${API_URL}/api/active-promotions`, payload).then(({ data }) => data)
    }
}

export default new PromotionsService()
