import { getToken } from '@/services/axios';
import AuthService from '@/services/auth.service';
const TIME_OUT_LOGOUT = process.env.VUE_APP_TIME_OUT_LOGOUT;

export default {
    data() {
        return {
            timer: null,
        };
    },
    mounted() {
        this.checkTimeOnReturn();
        this.resetTimer();
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
        ['mousemove', 'keypress', 'click', 'scroll'].forEach((event) => {
            document.addEventListener(event, this.resetTimer, true);
        });
    },
    beforeMount() {
        clearTimeout(this.timer);
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
        ['mousemove', 'keypress', 'click', 'scroll'].forEach((event) => {
            document.addEventListener(event, this.resetTimer, true);
        });
    },
    methods: {
        handleVisibilityChange() {
            if (document.visibilityState === 'hidden') {
                localStorage.setItem('lastTime', new Date().toISOString());
            } else if (document.visibilityState === 'visible') {
                this.checkTimeOnReturn();
            }
        },

        checkTimeOnReturn() {
            const token = getToken();
            const lastTime = localStorage.getItem('lastTime');
            if (lastTime) {
                const currentTime = new Date();
                const timeDiff = currentTime - new Date(lastTime);

                const minutesDiff = timeDiff / 1000 / 60;
                if (minutesDiff > TIME_OUT_LOGOUT) {
                    if (token) {
                        this.handleLogout();
                    }
                }
                localStorage.removeItem('lastTime');
            }
        },

        resetTimer() {
            const token = getToken();
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                if (token) {
                    this.handleLogout();
                }
            }, TIME_OUT_LOGOUT * 6 * 10000);
        },

        async handleLogout() {
            try {
                await AuthService.logout();
                this.$router.push('/login');
            } catch (error) {
                console.log(error);
            }
        },
    },
};
