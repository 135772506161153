import { getSettingDevice, isStrEmpty } from '@/utils/utils'
import store from '../store';

import axiosLib from 'axios'

const axiosAuth = axiosLib.create()

export function setToken(data) {
    localStorage.setItem('access_token', data)
}

export function getToken() {
    return localStorage.getItem('access_token')
}

export function getAccessToken() {
    return localStorage.getItem('access_token')
}

export function deleteToken() {
    localStorage.removeItem('access_token')
}

export const getUserInfo = () => {
    return localStorage.getItem('user_info') || ''
}

export const setUserInfo = (data) => {
    data = JSON.stringify(data)
    localStorage.setItem('user_info', data)
}

export const deteleUserInfo = () => {
    return localStorage.removeItem('user_info')
}

export const getLocale = () => {
    return localStorage.getItem('locale') || 'en'
}

export function deleteTokenV3() {
    localStorage.removeItem('access_tokenv3')
}

export const setTokenV3 = (token: string) => {
    return localStorage.setItem('access_tokenv3', token)
}

export const getTokenV3 = () => {
    return localStorage.getItem('access_tokenv3') || ''
}

function getRefreshToken() {
    return localStorage.getItem('refresh_token')
}

// axiosAuth.interceptors.request.use((config) => {
//     config.timeout = 30000
//     return config
// })

axiosAuth.interceptors.request.use((request) => {
    const timeoutSeconds = store?.state?.networkTimeoutSeconds ?? 30
    request.timeout = timeoutSeconds * 1000

    const token = getAccessToken()
    let setting: any = getSettingDevice()

    if (token) {
        request.headers.Authorization = token
        request.headers.ContentType = 'application/json'
    }

    if (!isStrEmpty(setting)) {
        setting = JSON.parse(setting)
        if (!setting.position) setting.position = {}
        setting.position.error_message = setting.location_error
        request.headers['Device-Network-Mac-Address'] = setting.mac
        request.headers['Device-Serial-Number'] = setting.serial
        request.headers['Device-Unique-Id'] = setting.uniqueId
        request.headers['App-Version'] = setting.appVersion
        request.headers['Device-Position'] = JSON.stringify(setting.position)
    }

    return request
})

axiosAuth.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
            return Promise.reject('ECONNABORTED')
        }

        if (error.response && error.response.data) {
            return Promise.reject(error.response.data)
        }
        return Promise.reject(error.message)
    }
)

export default axiosAuth
