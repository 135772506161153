import EventBus from '@/utils/EventBus'

export default {
    data() {
        return {
            isLoadingButton: false,
        }
    },
    mounted() {
        document.addEventListener('message', this.handleMessage)
    },
    methods: {
        showNotification(text, type) {
            const param = {
                text: text,
                type: type,
            }
            EventBus.$emit('show-notications', param)
        },

        handleMessage(event) {
            const resp = JSON.parse(event.data)
            if (resp.command === 'print-status') {
                if (resp.code === 200) {
                    this.showNotification('Print success', 'success')
                    this.isLoadingButton = false
                } else {
                    this.showNotification('Print error', 'error')
                    this.isLoadingButton = false
                }
            }
        },
    },
}
