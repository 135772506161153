import BaseService from './base.service'
const API_URL = process.env.VUE_APP_API_URL
const ACCOUNT = process.env.VUE_APP_ACCOUNT

class ResultService extends BaseService {
    async getList(date?: string, typeGame?: string) {
        const payload = {
            account: ACCOUNT,
        }

        if (date) {
            payload['date'] = date
        }
        return await this.postAuth2(`${API_URL}/api/${typeGame ? typeGame : 'multi-lotto'}/results`, payload).then(({ data }) => data)
    }

    /** only last 10 raffle promotions are returned */
    async getPromotionResult() {
        const payload = {
            account: ACCOUNT,
        }

        payload['promotion_type'] = 'raffle'

        return await this.postAuth2(`${API_URL}/api/promotion-result`, payload).then(({ data }) => data)
    }
}

export default new ResultService()
