<template>
    <div class="scan-ticket-detail">
        <LoadingScreen v-if="isLoadingScreen" />
        <template v-else>
            <h2 class="scan-ticket-detail__title">{{ getTranslatedText('scan ticket') }}</h2>
            <p class="scan-ticket-detail__desciption">{{ getTranslatedText('ticket details') }}</p>
            <div class="scan-ticket-detail__list-table">
                <div class="scan-ticket-detail__table">
                    <span class="scan-ticket-detail__table-title">{{ getTranslatedText('ticket details') }}</span>
                    <div class="scan-ticket-detail__table-item">
                        <span class="scan-ticket-detail__table-item-text scan-ticket-detail__column-label">{{ getTranslatedText('purchase date') }}</span>
                        <span class="scan-ticket-detail__table-item-text scan-ticket-detail__column-value"> {{ renderDate(dataDetail.created_at) }} </span>
                    </div>
                    <div class="scan-ticket-detail__table-item">
                        <span class="scan-ticket-detail__table-item-text scan-ticket-detail__column-label">{{ getTranslatedText('payment received') }}</span>
                        <span class="scan-ticket-detail__table-item-text scan-ticket-detail__column-value">{{ dataDetail.currency_code }} {{ renderPrice(dataDetail.total || dataDetail.amount) }} </span>
                    </div>
                    <div class="scan-ticket-detail__table-item">
                        <span class="scan-ticket-detail__table-item-text scan-ticket-detail__column-label">{{ getTranslatedText('ticket') }}</span>
                        <span class="scan-ticket-detail__table-item-text scan-ticket-detail__column-value">{{ ticketId }}</span>
                    </div>
                </div>
                <ItemTickets v-if="ticketType === 'L'" :details="dataSource.details" :events="dataSource.transaction.events" />
                <ItemSports v-else :dataSource="dataSource" />

                <div class="scan-ticket-detail__table-cancel" v-if="dataDetail.cancelations > 0">
                    <span class="scan-ticket-detail__table-item-text_no_upper_all_first">{{ getTranslatedText('Ticket is cancelled') }}</span>
                </div>
                <div class="scan-ticket-detail__table" v-else>
                    <span class="scan-ticket-detail__table-title">{{ Boolean(dataDetail.cancelled) ? getTranslatedText(dataDetail.cancelled_text || 'Ticket is canceled') : getTranslatedText('winnings') }} </span>
                    <div v-if="isCheckShowMakePatment" class="scan-ticket-detail__table-winnings">
                        <div class="scan-ticket-detail__table-item">
                            <span class="scan-ticket-detail__table-item-text scan-ticket-detail__column-label">{{ getTranslatedText('make payment') }}</span>
                            <span class="scan-ticket-detail__table-item-text scan-ticket-detail__column-value">{{ dataDetail.currency_code }} {{ renderMakePayment() }} </span>
                        </div>
                        <div class="scan-ticket-detail__table-winnings-button">
                            <ButtonCommon type="active" :isLoading="isLoadingSettle" :isNoHover="true" :value="isLoadingSettle ? 'settling' : 'settle'" nameFunction="on-call-api-settle" />
                        </div>
                    </div>

                    <div class="scan-ticket-detail__table-empty" v-if="dataDetail.winnings === 0 && dataDetail.cancelations <= 0">
                        <span>{{ getTranslatedText('no winnings') }}</span>
                    </div>

                    <div v-for="(payment, paymentIndex) in payments" :key="paymentIndex" class="scan-ticket-detail__table-payment-list">
                        <div class="scan-ticket-detail__table-item">
                            <span class="scan-ticket-detail__table-item-text scan-ticket-detail__column-label">{{ getTranslatedText('payment date') }}</span>
                            <span class="scan-ticket-detail__table-item-text scan-ticket-detail__column-value"> {{ renderDate(payment.payment_created_at) }} </span>
                        </div>
                        <div class="scan-ticket-detail__table-item">
                            <span class="scan-ticket-detail__table-item-text scan-ticket-detail__column-label">{{ getTranslatedText('amount') }}</span>
                            <span class="scan-ticket-detail__table-item-text scan-ticket-detail__column-value"> {{ dataDetail.currency_code }} {{ renderPrice(payment.payment_total) }} </span>
                        </div>
                    </div>
                </div>
                <div class="scan-ticket-detail__table" v-if="dataDetail.raffle_details && dataDetail.raffle_details.length > 0">
                    <span class="scan-ticket-detail__table-title">{{ getTranslatedText('raffle') }} </span>
                    <div class="scan-ticket-detail__table-item-draw" v-for="(value, index) in dataDetail.raffle_details" :key="index">
                        <hr v-if="index > 0"/>
                        <div class="scan-ticket-detail__table-item">
                            <span class="scan-ticket-detail__table-item-text scan-ticket-detail__column-label">{{ getTranslatedText('entries of promotion:') }} {{ value.promotion_id }}</span>
                            <span class="scan-ticket-detail__table-item-text scan-ticket-detail__column-value"> {{ value.entry_count }} </span>
                        </div>
                        <div class="scan-ticket-detail__table-empty" v-if="value.no_winnings_message && value.no_winnings_message !== ''">
                            <span style="text-transform: none !important;">{{ getTranslatedText(value.no_winnings_message) }}</span>
                        </div>
                        <div class="scan-ticket-raffle-detail__table" v-if="value.winnings && value.winnings.length > 0">
                            <table>
                                <thead>
                                    <tr>
                                        <th>{{ getTranslatedText('Win Tier') }}</th>
                                        <th>{{ getTranslatedText('Prize') }}</th>
                                        <th>{{ getTranslatedText('Paid At') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(w, i) in value.winnings" :key="i">
                                        <td>{{ w.tier }}</td>
                                        <td>{{ value.prize_type_slug === 'monetary' ? renderPrice(w.winnings) : w.winnings }}</td>
                                        <td>{{ w.paid_at ? renderDate(w.paid_at, true) : '' }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div v-if="!value.allow_cashier_to_pay && dataDetail.cashier_is_allowed_to_pay_raffle_promotion" class="scan-ticket-detail__table-winnings-button">
                                <ButtonCommon type="active" :value="getTranslatedText('contact lonagui')" />
                            </div>
                        </div>
                    </div>
                    <div v-if="dataDetail.cashier_is_allowed_to_pay_raffle_promotion && dataDetail.unpaid_raffle_winnings && dataDetail.unpaid_raffle_winnings > 0" class="scan-ticket-detail__table-winnings">
                        <hr/>
                        <div class="scan-ticket-detail__table-item">
                            <span class="scan-ticket-detail__table-item-text scan-ticket-detail__column-label">{{ getTranslatedText('make raffle payment') }}</span>
                            <span class="scan-ticket-detail__table-item-text scan-ticket-detail__column-value">{{ dataDetail.currency_code }} {{ raffleUnpaidAmount() }} </span>
                        </div>
                        <div class="scan-ticket-detail__table-winnings-button">
                            <ButtonCommon type="active" :isLoading="isLoadingSettle" :isNoHover="true" :value="isLoadingSettle ? 'settling' : 'settle'" nameFunction="on-call-api-settle-raffle" />
                        </div>
                    </div>
                    <div v-if="!dataDetail.cashier_is_allowed_to_pay_raffle_promotion 
                            && ((dataDetail.unpaid_raffle_winnings && dataDetail.unpaid_raffle_winnings > 0)
                            || dataDetail.have_non_money_raffle_winnings)" class="scan-ticket-detail__table-winnings-button">
                        <hr style="margin-top: 0;"/>
                        <ButtonCommon type="active" :value="getTranslatedText('contact lonagui')" />
                    </div>
                </div>
            </div>
        </template>

        <v-dialog persistent v-model="dialogSettle" width="100%">
            <v-card class="scan-ticket-detail__box-modal">
                <div class="scan-ticket-detail" v-if="printData">
                    <span class="scan-ticket-detail__box-modal-title">{{ getTranslatedText('ticket details') }}</span>
                    <div class="scan-ticket-detail__table-item">
                        <span class="scan-ticket-detail__table-item-text scan-ticket-detail__column-label">{{ getTranslatedText('payment date') }}</span>
                        <span class="scan-ticket-detail__table-item-text scan-ticket-detail__column-value"> {{ renderDate(printData.date, true) }} </span>
                    </div>
                    <div class="scan-ticket-detail__table-item">
                        <span class="scan-ticket-detail__table-item-text scan-ticket-detail__column-label">{{ getTranslatedText('amount') }}</span>
                        <span class="scan-ticket-detail__table-item-text scan-ticket-detail__column-value">{{ dataDetail.currency_code }} {{ renderPrice(printData.winnings) }} </span>
                    </div>
                    <div class="scan-ticket-detail__table-modal-button">
                        <ButtonCommon type="active" className="btn-close-modal" :isNoHover="true" value="close" nameFunction="on-cancel-modal-settle" />
                        <ButtonCommon type="active" className="btn-print-modal" :isNoHover="true" value="print" nameFunction="on-print-settle" />
                    </div>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import EventBus from '@/utils/EventBus'
import BetslipsService from '@/services/betslip.service'
import languageMixins from '@/mixins/language/language-mixins'
import printMixins from '@/mixins/print/print-mixin'
import ItemTickets from '@/components/ScanTicket/item/ticket.vue'
import ItemSports from '@/components/ScanTicket/item/sport.vue'
import RadioCommon from '@/components/common/radio/index.vue'
import LoadingScreen from '@/components/common/loading/index.vue'
import ButtonCommon from '@/components/common/button/index.vue'

import { formatDateUTC00, formatPrice, isObjEmpty } from '@/utils/utils'

export default {
    mixins: [languageMixins, printMixins],
    components: { RadioCommon, LoadingScreen, ItemTickets, ItemSports, ButtonCommon },
    props: {
        dataSource: {
            type: Object,
            default: () => {},
        },
        ticketId: {
            type: String,
            default: '',
        },
        ticketType: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isLoadingScreen: true,
            payments: null,
            isCheckShowMakePatment: false,
            dataDetail: null,
            printData: null,
            isLoadingSettle: false,
            dialogSettle: false,
            settleRaffle: false
        }
    },
    created() {
        this.fetchData()
        EventBus.$on('on-call-api-settle', () => this.settleTicket())
        EventBus.$on('on-call-api-settle-raffle', () => this.settleRaffleTicket())
        EventBus.$on('on-cancel-modal-settle', () => {
            this.dialogSettle = false
            this.onSearchTicketDetail()
        })
        EventBus.$on('on-print-settle', () => this.onPrintSettle())
    },
    destroyed() {
        EventBus.$off('on-call-api-settle-raffle')
        EventBus.$off('on-call-api-settle')
        EventBus.$off('on-cancel-modal-settle')
        EventBus.$off('on-print-settle')
    },
    methods: {
        showNotification(text, type) {
            const param = {
                text: text,
                type: type,
            }
            EventBus.$emit('show-notications', param)
        },

        async onSearchTicketDetail() {
            this.isLoadingScreen = true
            try {
                const response = await BetslipsService.searchBetslip(this.ticketId)

                this.dataSource = response.data
                this.fetchData()
            } catch (error) {
                console.log(error)
            }
        },

        fetchData() {
            let dataDetail = this.ticketType === 'L' ? this.dataSource.transaction : this.dataSource.details
            if (this.ticketType === 'S') dataDetail['paid'] = 0

            let isCheckWinnings = parseFloat(dataDetail.winnings) > 0 && parseFloat(dataDetail.winnings) > parseFloat(dataDetail.paid) + parseFloat(dataDetail.payments)
            let isCheckCancelations = parseFloat(dataDetail.cancelations) > 0 && parseFloat(dataDetail.cancelations) > parseFloat(dataDetail.paid) + parseFloat(dataDetail.payments)
            if (isCheckWinnings || isCheckCancelations) {
                this.isCheckShowMakePatment = true
            } else {
                this.isCheckShowMakePatment = false
            }

            if (this.ticketType === 'L') {
                const payments = this.dataSource.details.filter((v) => {
                    return v.payment_total && v.payment_total > 0
                })
                this.payments = payments
                this.isLoadingScreen = false
            }
            if (this.ticketType === 'S') {
                if (dataDetail.payments > 0) {
                    const payments = [
                        {
                            payment_created_at: dataDetail.paid_date,
                            payment_total: dataDetail.payments,
                        },
                    ]

                    this.payments = payments
                }
                this.isLoadingScreen = false
            }
            this.dataDetail = dataDetail
        },

        async settleTicket() {
            this.isLoadingSettle = true
            try {
                let raffle = this.settleRaffle
                this.settleRaffle = false
                const response = await BetslipsService.settleBetslip(this.ticketId, raffle)
                this.settleRaffle = false
                if (response.status === 200) {
                    this.printData = response.data
                    this.dialogSettle = true
                    this.isLoadingSettle = false
                } else {
                    this.showNotification(response.message, 'error')
                    this.isLoadingSettle = false
                }
            } catch (error) {
                console.log(error)
            }
        },

        async settleRaffleTicket() {
            this.settleRaffle = true
            return this.settleTicket()
        },

        onPrintSettle() {
            let param = this.printData
            parent.postMessage({ command: 'print-winning-settle', param }, '*')
            this.printSettle(param)
            this.dialogSettle = false
            this.onSearchTicketDetail()
        },

        renderMakePayment() {
            const price = parseFloat(this.dataDetail.winnings) + parseFloat(this.dataDetail.cancelations) - (parseFloat(this.dataDetail.paid) + parseFloat(this.dataDetail.payments))
            return formatPrice(price)
        },

        raffleUnpaidAmount() {
            return formatPrice(this.dataDetail.unpaid_raffle_winnings)
        },

        renderDate(date, isShowTime) {
            const dateFormat = formatDateUTC00(date)

            let valueDate = `${dateFormat.day}/${dateFormat.month}/${dateFormat.year} ${dateFormat.hour}:${dateFormat.minutes}`

            return valueDate
        },

        renderPrice(price) {
            return formatPrice(price)
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/scan-ticket/_detail.scss';
</style>
