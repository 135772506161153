<template>
    <div class="print">
        <h2 class="print__title">{{ getTranslatedText('SETTINGS') }}</h2>
        <div class="print__box-radio-button">
            <RadioCommon value="1" label="Select Printer" :valueChecked="step" nameFunction="on-select-step-print" paramFunction="1" />
            <RadioCommon value="2" label="Use Inner-Printer" :valueChecked="step" nameFunction="on-select-step-print" paramFunction="2" />
        </div>
        <template v-if="step === '1'">
            <h5 class="print__title-list">{{ getTranslatedText('Printers') }}</h5>
            <LoadingScreen v-if="isLoadingScreen" />
            <template v-else>
                <div class="print__device" v-if="dataSource.length > 0">
                    <div class="print__device-list">
                        <div class="print__device-item" v-for="(v, i) in dataSource" :key="i">
                            <span class="print__text">{{ v.name }}</span>
                            <ButtonCommon :isLoading="isLoadingButton" value="Select" nameFunction="on-connect-bluetooth" />
                        </div>
                    </div>
                    <span class="print__text">{{ getTranslatedText(`Go to device's Bluetooth Setting to add more printer if your  device is not here.`) }}</span>
                </div>
                <div class="print__device-empty" v-else>
                    <span class="print__text">{{ getTranslatedText('Found no printer!') }}</span>
                    <span class="print__text">{{ getTranslatedText(`Go to device's Bluetooth Setting to add printer.`) }}</span>
                </div>
            </template>
            <div class="print__box-button">
                <ButtonCommon className="w100" :isNoHover="true" :isLoading="isLoadingButton" value="Refresh printer list" nameFunction="on-refresh-printer-list" />
                <ButtonCommon className="w100" :isNoHover="true" :isLoading="isLoadingButton" value="test print" nameFunction="on-test-print" />
            </div>
        </template>
        <template v-else>
            <span class="print__text text-turn">{{ getTranslatedText(`Try to turn on bluetooth so inner-printer can work properly!`) }}</span>
            <ButtonCommon className="w100" :isNoHover="true" :isLoading="isLoadingButton" value="test print" nameFunction="on-test-print" />
        </template>
    </div>
</template>
<script>
import languageMixins from '@/mixins/language/language-mixins'
import printMinxin from '@/mixins/print/print-mixin'
import messagePrint from '@/mixins/messagePrint/message-print-mixins'
import RadioCommon from '@/components/common/radio/index.vue'
import ButtonCommon from '@/components/common/button/index.vue'
import LoadingScreen from '@/components/common/loading/index.vue'

import EventBus from '@/utils/EventBus'

export default {
    mixins: [languageMixins, messagePrint, printMinxin],
    components: { RadioCommon, ButtonCommon, LoadingScreen },
    data() {
        return {
            step: '1',
            dataSource: [],
            defaultPrinter: null,
            isLoadingScreen: false,
        }
    },
    created() {
        EventBus.$on('on-select-step-print', (step) => (this.step = step))
        EventBus.$on('on-refresh-printer-list', () => this.onRefreshPrinterList())
        EventBus.$on('on-test-print', () => this.onTestPrint())
        EventBus.$on('on-connect-bluetooth', () => this.onConnectBluetooth())
        this.fetchDataBluetooth()
    },
    destroyed() {
        EventBus.$off('on-select-step-print')
        EventBus.$off('on-refresh-printer-list')
        EventBus.$off('on-test-print')
        EventBus.$off('on-connect-bluetooth')
    },
    mounted() {
        document.addEventListener('message', this.handleMessage)
    },
    methods: {
        handleMessage(event) {
            const resp = JSON.parse(event.data)
            if (resp.command === 'list-bluetool') {
                this.isLoadingScreen = false
                this.isLoadingButton = false
                this.dataSource = resp.data
            }
            if (resp.command === 'return-connect-bluetool') {
                if (code === 200 && resp.data) {
                    this.defaultPrinter = resp.data
                } else if (code !== 200) {
                    const param = {
                        text: 'Could not connect printer! Please try again!',
                        type: 'warning',
                    }
                    EventBus.$emit('show-notications', param)
                } else {
                    return false
                }
            }
        },

        fetchDataBluetooth() {
            parent.postMessage({ command: 'get-list-bluetooth' }, '*')
        },

        onRefreshPrinterList() {
            parent.postMessage({ command: 'get-list-bluetooth' }, '*')
            this.isLoadingScreen = true
            this.isLoadingButton = true
        },

        onConnectBluetooth() {
            parent.postMessage({ command: 'connect-bluetooth', data: this.dataSource }, '*')
            this.isLoadingButton = true
        },

        onTestPrint() {
            this.isLoadingButton = true
            parent.postMessage({ command: 'test-print' }, '*')
            this.isLoadingButton = false
            this.printTest()
        },
    },
}
</script>
<style lang="scss">
@import '~ThemePath/scss/print/_index.scss';
</style>
