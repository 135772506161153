import BaseService from './base.service'
import { deleteToken, deleteTokenV3 } from '@/services/axios'
import store from '@/store/index'

const API_URL = JSON.parse(process.env.VUE_APP_SETTING_JSON).url
const API_URL_V3 = process.env.VUE_APP_API_URL
const SETTING_JSON = process.env.VUE_APP_SETTING_JSON
const CUSTOMER_ID = process.env.VUE_APP_CUSTOMER_ID
interface LoginPayload {
    terminal_id: string
    terminal_pin: string
    login_method: string
    ignore_cache: boolean
}

class AuthService extends BaseService {
    login(payload: LoginPayload) {
        let newDate = new Date().valueOf()
        payload.ignore_cache = true
        return this.post(API_URL + `/api/retail/session/login?t=${newDate}`, payload).then((response) => {
            return response.data
        })
    }

    async getTokenV3User() {
        const payload = {
            account: store.state.userInfo.gamev3.account,
            currency: store.state.userInfo.gamev3.currency_code,
            customerID: CUSTOMER_ID,
        }

        return await this.postAuth2(API_URL_V3 + `/api/token`, payload)
    }

    logout() {
        deleteToken()
        deleteTokenV3()
        store.dispatch('onClearCartAll') 
        store.dispatch('removeUserInfo') 
    }

    readSettingFromEnv() {
        let setting
        try {
            setting = JSON.parse(SETTING_JSON)
        } catch (e) {
            console.log(e)
        }
        return setting
    }
}

export default new AuthService()
