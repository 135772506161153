<template>
  <form>
      <v-row class="form">
        <v-col
          cols="12"
          md="12"
          v-for="(item, index) in formItem"
          :key="index"
        >
          <div class="field">
            <label class="label">{{ getTranslatedText(item.label) }}</label>
            <div class="control">
                <input
                  v-if="item.type==='password'"
                  :class="item.className ? item.className : 'input-text'"
                  :type="item.type"
                  :value="dataForm[`${item.name}`]"
                  pattern="[0-9]*"
                  inputmode="numeric"
                  :required="item.required"
                  @input="value => onChangeValue(value, item.name)"
                />
              <input
                  v-if="item.type!=='password'"
                  :class="item.className ? item.className : 'input-text'"
                  :type="item.type"
                  :value="dataForm[`${item.name}`]"
                  :required="item.required"
                  @input="value => onChangeValue(value, item.name)" />
            </div>
          </div>
        </v-col>
      </v-row>
  </form>
</template>

<script>
import languageMixins from '@/mixins/language/language-mixins'
import { isStrEmpty } from '@/utils/utils'
import EventBus from '@/utils/EventBus'

export default {
    mixins: [languageMixins],
    props: {
        formItem: {
            type: Array,
            default: () => [],
        },
        dataForm: {
            type: Object,
            default: () => {
                return {};
            },
        },
        nameFunction: {
            type: String,
            default: '',
        },
    },
    data() {
        return {

        };
    },
    methods: {
      onChangeValue(event, name) {
          const param = { event, name }
          if (!isStrEmpty(this.nameFunction)) {
            EventBus.$emit(this.nameFunction, param)
          }
      },
    }
};
</script>

<style lang="scss">
@import '~ThemePath/scss/common/_form.scss';
</style>
