<template>
  <div class="change_pin__wrap">
    <div class="change_pin__container">
      <h2 class="change_pin__title">{{ getTranslatedText('Change PIN') }}</h2>
      <form action="" class="change_pin__form">
        <div class="change_pin__form--ip">
          <label for="old_pin">{{ getTranslatedText('old pin') }}</label>
          <input v-model="oldPin" type="password" name="old_pin" id="old_pin">
        </div>
        <div class="change_pin__form--ip">
          <label for="new_pin">{{ getTranslatedText('new pin') }}</label>
          <input v-model="newPin" type="password" name="new_pin" id="new_pin">
        </div>
        <div class="change_pin__form--ip">
          <label for="new_pin_confirm">{{ getTranslatedText('new pin retype') }}</label>
          <input v-model="newPin2" type="password" name="new_pin_confirm" id="new_pin_confirm">
        </div>
        <div class="form-action">
          <input @click.prevent="handleSubmit(oldPin, newPin, newPin2)" type="submit" :value="getTranslatedText('Change PIN')" :disabled="isLoading">
          <Loading v-if="isLoading" :isLoadingPage="false" />
        </div>
      </form>
      <p class="change_pin--note">{{ getTranslatedText('cashier need to login again after PIN is changed.') }}</p>
    </div>
  </div>
</template>
<script>
import EventBus from "@/utils/EventBus";
import languageMixins from '@/mixins/language/language-mixins'
import PinService from '@/services/pin.service'
import Loading from '@/components/common/loading/index.vue'

export default {
  mixins: [languageMixins],
  components: { Loading },
  data() {
    return {
      isLoading: false,
      oldPin: '',
      newPin: '',
      newPin2: '',
    }
  },
  methods: {
    handleSubmit(oldPin, newPin, newPin2){
      this.isLoading = true;
      try {
        if (
            !oldPin ||
            oldPin.trim() === '' ||
            !newPin ||
            newPin.trim() === '' ||
            !newPin2 ||
            newPin2.trim() === ''
        ) {
          const param = {
            text: 'please input old pin and new pin!',
            type: 'warning',
          }
          EventBus.$emit('show-notications', param)
          this.isLoading = false;
          return false;
        }
        PinService.changePin(oldPin.trim(), newPin.trim(), newPin2.trim()).then(res=> {
          if (res.status !== '200' && res.status !== 200) {
            let message = ''
            if (res.message && res.message !== '') {
              message = res.message
            } else {
              message = res.errors[0].message
            }
            const param = {
              text: message,
              type: 'error',
            }
            EventBus.$emit('show-notications', param)
          } else {
            const param = {
              text: 'SUCCESS',
              type: 'success',
            }
            EventBus.$emit('show-notications', param)
          }
          this.isLoading = false;
        })
      } catch (e) {
        this.isLoading = false;
        console.log(e, 'err')
      }
    }
  }
}
</script>
<style lang="scss">
@import '~ThemePath/scss/change-pin/_index.scss';
</style>

