<template>
    <div>
        <LoadingScreen v-if="isLoadingScreen"></LoadingScreen>
        <div class="loto__numbers" v-else>
            <div class="loto__list__logo">
                <img src="~ThemePath/images/logo.png" />
            </div>
            <div class="loto__list__head">
                <div class="loto__list__head__name">
                    {{ getTranslatedText(`guinne ${dataSource.title }`) }}
                </div>
                <div class="loto__list__head__title">{{ dataSource.title }} {{ dataSource.gameNumber }}</div>
            </div>
            <div class="loto__numbers__quick-pick">
                <SelectPickNumber v-if="allowedPicks" :rangePickNumber="rangePickNumber" @select-pick="selectNumberPick" />
                <ButtonCommon v-else :isNoHover="false" value="Quick Pick"
                    className="screen-number__btn-quick-pick" nameFunction="on-quick-pick-number" />
            </div>
            <div class="loto__numbers__list">
                <div class="loto__numbers__item" :class="{ active: selection.includes(value.number) }" v-for="(value, index) in listNumber" :key="index" @click="() => onSelectNumber(value.number)">
                    {{ value.number }}
                </div>
            </div>
            <div class="loto__numbers__value-number">
                <input autocomplete="off" @keyup="removeDotDash" v-model="valueSelection" class="loto__numbers__input" type="text" pattern="[0-9]" inputmode="numeric" @blur="onBlurValueSelection" ref="inputValueNumber" @keyup.enter="hideKeypad('inputValueNumber')" />
            </div>
            <div class="screen-number__footer">
                <div class="screen-number__container-price">
                    <div class="screen-number__info">
                        <span class="screen-number__text bold">{{ getTranslatedText(`${dataSource.title}`) }} {{ dataSource.gameNumber }}</span>
                        <span class="screen-number__text">{{ renderPrice(dataSource.wager, 2) }}</span>
                    </div>
                    <div class="screen-number__box-input">
                        <span class="screen-number__box-input--text wager">
                            <b>{{ getTranslatedText("wager ticket") }}</b>
                            <span>
                                ({{ getTranslatedText("min") }}
                                {{ renderPrice(WAGER_MIN_ENV, 0) }} - {{ getTranslatedText("max") }}
                                {{ renderPrice(WAGER_MAX_ENV, 0) }})
                            </span>
                        </span>
                        <input v-model="priceNumber" class="screen-number__input price" step="1" min="0" max="100" pattern="[0-9]*" inputmode="numeric" @blur="onBlurPriceNumber" v-on:keypress="onlyNumber" ref="inputPrice" @keyup.enter="hideKeypad('inputPrice')" />
                    </div>
                    <div class="screen-number__box-button">
                        <ButtonCommon :value="`+${renderPrice('1000', 0)}`" :paramFunction="1000"
                            nameFunction="on-change-price-number" />
                        <ButtonCommon :value="`+${renderPrice('5000', 0)}`" :paramFunction="5000"
                            nameFunction="on-change-price-number" />
                        <ButtonCommon :value="`+${renderPrice('10000', 0)}`" :paramFunction="10000"
                            nameFunction="on-change-price-number" />
                    </div>
                </div>
                <div class="screen-number__info screen-number__info__custom">
                    <span class="screen-number__text bold">{{ getTranslatedText('total') }}</span>
                    <span class="screen-number__text bold">{{ renderPrice(totalNumber) }}</span>
                </div>
                <div class="screen-number__box-button screen-number__box-button__custom">
                    <ButtonCommon :isNoHover="true" :class-name="'screen-number__box-button__clear'" value="clear" nameFunction="on-show-dialog-clear" />
                    <ButtonCommon :class-name="'screen-number__box-button__add-to-cart'" :isNoHover="true" type="error" value="add to cart" nameFunction="on-add-to-cart" :isLoading="$store.state.isLoadingAddCart" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LoadingScreen from "@/components/common/loading/index.vue";
import languageMixins from "@/mixins/language/language-mixins";
import EventBus from "@/utils/EventBus";
import ButtonCommon from "@/components/common/button/index.vue";
import SelectPickNumber from "@/components/games/number-quick-pick.vue";
import { formatPrice, isStrEmpty, removeDotDash } from "@/utils/utils";
const WAGER_MIN_ENV = process.env.VUE_APP_WAGER_MIN || 2000;
const WAGER_MAX_ENV = process.env.VUE_APP_WAGER_MAX || 50000;

export default {
    mixins: [languageMixins],
    components: { ButtonCommon, LoadingScreen, SelectPickNumber },
    data() {
        return {
            dataSource: {},
            selection: [],
            listNumber: [],
            rangePickNumber: [],
            valueSelection: "",
            WAGER_MIN_ENV,
            WAGER_MAX_ENV,
            priceNumber: 0,
            totalNumber: 0,
            defaultWager: 0,
            numberQp: 2,
            isLoadingScreen: true,
            allowedPicks: false
        };
    },
    mounted() {
        this.fetchData()
    },
    created() {
        EventBus.$on('on-change-price-number', (param) => this.onChangePriceNumber(param))
        EventBus.$on('on-quick-pick-number', () => this.onClickQuickPick())
        EventBus.$on('on-clear-select-number', () => this.onClearSelectNumber())
        EventBus.$on('on-show-dialog-clear', () => this.onShowConfirmClear())
        EventBus.$on('on-add-to-cart', () => this.onAddToCart())
    },
    destroyed() {
        EventBus.$off('on-change-price-number')
        EventBus.$off('on-quick-pick-number')
        EventBus.$off('on-clear-select-number')
        EventBus.$off('on-show-dialog-clear')
        EventBus.$off('on-add-to-cart')
    },
    watch: {
        selection() {
            this.renderValueNumber()
        },
        priceNumber() {
            let price = this.priceNumber.replace(/,/g, '')
            this.totalNumber = parseFloat(price) * this.dataSource.event_id.length
            this.dataSource.wager = parseFloat(price)
        },
        dataSource() {
            this.$nextTick(() => {
                this.scrollByOnePixel()
            })
        },
    },
    methods: {
        scrollByOnePixel() {
            window.scrollBy(0, 1)
        },

        fetchData() {
            let dataNumber = localStorage.getItem('loto-number')
            if (isStrEmpty(dataNumber)) {
                this.$router.push('/loto')
            }
            dataNumber = JSON.parse(dataNumber);

            let allowedPicks = dataNumber.allowedPicks;
            this.dataSource = dataNumber;
            this.allowedPicks = allowedPicks;
            //this.numberQp = dataNumber.numberQp;
            this.defaultWager = dataNumber.defaultWager;
            this.priceNumber = dataNumber.priceNumber;
            this.listNumber = Array.from(Array(90), (_, i) => {
                return { number: ++i };
            });
            this.rangePickNumber = allowedPicks ? Array.from({ length: allowedPicks.max - allowedPicks.min + 1 }, (_, index) => index + allowedPicks.min) : []
            this.isLoadingScreen = false;
        },

        onlyNumber(event) {
            if (event.key === '-' && event.target.selectionStart !== 0) {
                event.preventDefault()
                return
            }
            if (event.keyCode < 48 || event.keyCode > 57) {
                event.preventDefault()
            }
        },

        onAddToCart() {
            this.$store.state.isLoadingAddCart = true
            const allowedPicks = this.dataSource.allowedPicks
            const selectionNumber = this.selection.length;

            if (allowedPicks && (selectionNumber < allowedPicks.min || allowedPicks.max < selectionNumber)
                || (!allowedPicks && this.dataSource.numberQp > selectionNumber)
            ) {
                let text = allowedPicks ? `Please make sure that you select from ${allowedPicks.min} to ${allowedPicks.max} of balls!`
                    : `Please make sure that you select ${this.dataSource.numberQp} of balls!`
                const param = {
                    text: text,
                    type: "warning",
                };
                EventBus.$emit('show-notications', param)
                this.$store.state.isLoadingAddCart = false

                return false
            }
            const param = this.dataSource
            let wager = this.priceNumber.replace(/,/g, '')
            param['selection'] = this.selection
            param['date'] = this.selection
            param['wager'] = parseFloat(wager)
            param['totalCart'] = this.totalNumber
            param['id'] = new Date().getTime().toString(36).concat(performance.now().toString(), Math.random().toString()).replace(/\./g, '')
            param['gameType'] = 'loto-590'
            this.$store.dispatch('onAddToCart', param)
            this.onClearSelectNumber()
            localStorage.removeItem('loto645-event')
        },

        onClearSelectNumber() {
            this.priceNumber = this.defaultWager
            this.selection = []
        },

        onShowConfirmClear() {
            const param = {
                title: process.env.VUE_APP_I18N_LOCALE === 'fr' ? 'warning' : 'confirm delete',
                description: 'Are you sure you want to delete?',
                nameFunction: 'on-clear-select-number',
            }
            EventBus.$emit('on-show-dialog', param)
        },

        onClickQuickPick() {
            const allowedPicks = this.dataSource.allowedPicks
            let numberSelect = allowedPicks ? this.numberQp : this.dataSource.numberQp

            let numbers = Array(90)
                .fill(0)
                .map((_, index) => index + 1);
            numbers.sort(() => Math.random() - 0.5);
            numbers = [...numbers.slice(0, numberSelect)];
            this.selection = [...numbers];
        },

        renderValueNumber() {
            const selection = [...this.selection]
            const numberSelect = selection.sort((a, b) => a - b)

            this.valueSelection = this.renderTextTickets(numberSelect)
        },

        onChangePriceNumber(value) {
            let price = value || 0
            let priceNumber = this.priceNumber.replace(/,/g, '')
            let priceMore = parseFloat(priceNumber) + price
            if (priceMore > WAGER_MAX_ENV) priceMore = WAGER_MAX_ENV
            this.priceNumber = this.renderPrice(priceMore, 0)
            this.dataSource.wager = priceMore
        },

        renderTextTickets(arr) {
            let valueNumberSelectRender = ''
            arr.forEach((x, index) => {
                valueNumberSelectRender = `${valueNumberSelectRender}${index !== 0 ? ',' : ''} ${x}`
            })
            return valueNumberSelectRender
        },

        onBlurPriceNumber() {
            let priceNumber = this.priceNumber.replace(/,/g, '')
            let price = priceNumber !== '' ? priceNumber : 0
            let value = parseFloat(price)
            if (value < this.WAGER_MIN_ENV) value = this.WAGER_MIN_ENV
            if (value > this.WAGER_MAX_ENV) value = this.WAGER_MAX_ENV
            this.priceNumber = this.renderPrice(value, 0)
        },

        onBlurValueSelection() {
            let inputArray = this.valueSelection
                .split(',')
                .map((num) => Number(num.trim()))
                .filter((num, index, self) => num >= 1 && num <= 90 && index === self.indexOf(num))

            inputArray = inputArray.slice(0, this.dataSource.numberQp)
            this.valueSelection = inputArray.join(',')
            this.selection = [...inputArray]
        },

        removeDotDash (e) {
            this.valueSelection = removeDotDash(this.valueSelection)
        },

        onSelectNumber(number) {
            const allowedPicks = this.dataSource.allowedPicks
            const selection = this.selection
            const index = selection.findIndex((x) => x === number)
            if (index !== -1) {
                selection.splice(index, 1)
            } else {
                if ((!allowedPicks && this.dataSource.numberQp > this.selection.length) ||
                    (allowedPicks && this.selection.length < allowedPicks.max)) {
                    selection.push(number)
                }
            }

            this.selection = [...selection]
        },

        renderPrice(price, decimal) {
            return formatPrice(price, decimal)
        },

        hideKeypad(ref) {
            this.$refs[ref].blur()
        },

        selectNumberPick(pick) {
            this.numberQp = pick;
            this.onClickQuickPick();
        }
    },
}
</script>
<style lang="scss">
@import '~ThemePath/scss/loto/_index.scss';
</style>
