<template>
    <v-main class="main-app">
        <div class="main-content">
            <EventsComponent />
        </div>
    </v-main>
</template>

<script>
import EventsComponent from "@/components/games459/events/index.vue";
export default {
    components: { EventsComponent },
    data() {
        return {};
    },
};
</script>
