import BaseService from './base.service'

const API_URL = JSON.parse(process.env.VUE_APP_SETTING_JSON).url

class PinService extends BaseService {
    changePin(oldPin: string, newPin: string, newPinRetype: string) {
        const payload = {
            old_password: oldPin,
            new_password: newPin,
            new_password_retype: newPinRetype,
            type_of_pass: 'terminal_pin',
        }
        return this.post(
            `${API_URL}/api/retail/change-password`,
            payload,
        ).then(({ data }) => data)
    }
}

export default new PinService()
