/* eslint-disable max-len */
import { RouteConfig } from "vue-router";

import { metaRequireGuest } from "@/router/meta-constants";
import LoginPage from "@/views/login/index.vue";


const authenticateRoutes: Array<RouteConfig> = [
    // {
    //     path: "/example",
    //     name: "example",
    //     meta: metaRequireGuest,
    //     component: example,
    // }
    {
        path: "/login",
        name: "Login",
        meta: metaRequireGuest,
        component: LoginPage,
    },
];

export default authenticateRoutes;
